import React, { Component } from "react";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import Lottie from "react-lottie";
import animation from "../../lottieAnimations/ThisWay-LogoLoading.json";
import {
  Home,
  WorkThisWay,
  Events,
  Meetings,
  AboutThisWay,
  Locations,
  Stories,
  WhyThisWay,
  GalleryTour,
  OpenedStory,
  PrivacyPolicy,
} from "../../screens";
import { NewHeader, Footer, MobileHeader } from "../../components";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animation,
  renderSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      cookies: false,
    };
  }
  componentDidMount() {
    const agreedToCookies = localStorage.getItem("agreeToCookies");
    if (agreedToCookies) {
      this.setState({ cookies: true });
    } else {
      this.setState({ cookies: false });
    }
    document.body.style.overflowY = "hidden";
    setTimeout(() => {
      this.setState({ loading: false });
      if (!agreedToCookies) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "scroll";
      }
    }, 500);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cookies) {
      document.body.style.overflowY = "scroll";
    }
  }
  render() {
    return (
      <div className="dashboard-wrap">
        {!this.state.cookies && (
          <div
            className="cookies-overlay"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100vw",
              height: "100vh",
              zIndex: "9999",
              backgroundColor: "rgba(0, 0, 0, 0.450)",
            }}
          >
            <div
              className="cookies-container"
              style={
                window.innerWidth < 900
                  ? {
                      width: "100vw",
                      height: "200px",
                      position: "absolute",
                      bottom: "3%",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 20px 40px 20px",
                      fontSize: "12px",
                    }
                  : {
                      width: "100vw",
                      height: "150px",
                      position: "absolute",
                      bottom: "0",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "0 40px",
                    }
              }
            >
              <p style={{ width: "80%" }}>
                We use cookies in order to improve the navigation, the
                performance and the functionality of our website. For more
                information regarding the cookies, please read our
                <p
                  style={{
                    display: "inline",
                    margin: "0 6px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("/docs/Draft_Cookies_Policy_05.04.2021.pdf")
                  }
                >
                  Cookies Policy.
                </p>
                By using our website you agree with our
                <p
                  style={{
                    display: "inline",
                    margin: "0 6px",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    window.open("/docs/Draft_Cookies_Policy_05.04.2021.pdf")
                  }
                >
                  Cookies Policy.
                </p>
              </p>
              <div
                className="flex-container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  localStorage.setItem("agreeToCookies", true);
                  this.setState({ cookies: true });
                  document.body.style.overflowY = "scroll";
                }}
              >
                <p>ACCEPT</p>
                <div
                  className="arrow-btn"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginLeft: "10px",
                    backgroundImage: "url(/icons/arrow-right-black.svg)",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <div
          style={{
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            position: "absolute",
            top: "0",
            left: "0",
            zIndex: "9999",
            backgroundColor: "black",
            opacity: this.state.loading ? "1" : "0",
            display: this.state.loading ? "flex" : "none",
          }}
        >
          <Lottie
            options={defaultOptions}
            height={this.state.loading ? 300 : 0}
            width={this.state.loading ? 300 : 0}
          />
        </div>
        {window.innerWidth < 900 ? <MobileHeader /> : <NewHeader />}
        <Switch>
          <Route exact path="/:lang?" component={Home} />
          <Route path="/:lang?/WorkThisWay" component={WorkThisWay} />
          <Route path="/:lang?/Events" component={Events} />
          <Route path="/:lang?/Meetings" component={Meetings} />
          <Route path="/:lang?/AboutThisWay" component={AboutThisWay} />
          <Route path="/:lang?/Locations" component={Locations} />
          <Route path="/:lang?/Stories" component={Stories} />
          <Route path="/:lang?/WhyThisWay" component={WhyThisWay} />
          <Route path="/:lang?/GalleryTour" component={GalleryTour} />
          <Route path="/:lang?/SingleStory" component={OpenedStory} />
          <Route path="/:lang?/PrivacyPolicy" component={PrivacyPolicy} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default withRouter(Routes);
