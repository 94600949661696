import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setLocale } from "../../translations/localization";
import { setLanguage, setLanguageToLocalStorage } from "../../actions";
import { NavLink, useLocation } from "react-router-dom";
import { HeaderDropdown } from "../../components";
import "./styles.scss";

function NewHeader({ language, setLanguage, setLanguageToLocalStorage }) {
  const [selected, setSelected] = useState("");
  const url = useLocation().pathname.split(`/${language}/`).pop();
  useEffect(() => {
    if (url === "WorkThisWay") {
      setSelected("WORK THIS WAY");
    } else if (url === "Events" || url === "Meetings") {
      setSelected("MEET THIS WAY");
    } else if (url === "WhyThisWay" || url === "GalleryTour") {
      setSelected("WHY THIS WAY");
    } else if (
      url === "AboutThisWay" ||
      url === "Stories" ||
      url === "Locations"
    ) {
      setSelected("ABOUT THIS WAY");
    } else {
      return;
    }
  }, [url]);

  useEffect(() => {
    if (!window.localStorage.code) {
      setLanguage("en");
      setLanguageToLocalStorage("en");
      setLocale("en");
    }
  }, [setLanguage, setLanguageToLocalStorage]);
  return (
    <div
      className={`new-header-container ${
        url === "PrivacyPolicy" && "display-none"
      }`}
    >
      <div className="main-header-content">
        <NavLink to={`/${language}/WorkThisWay`}>
          <div
            onClick={() => setSelected("WORK THIS WAY")}
            className={`header-element border-right ${
              selected === "WORK THIS WAY" ? "selected" : ""
            }`}
          >
            <p id="bold">WORK</p>
            <p id="light"> THIS WAY</p>
            <div className="bottom-line" />
          </div>
        </NavLink>
        <a href={`/${language}/Events`}>
          <div
            className={`header-element border-right ${
              selected === "MEET THIS WAY" ? "selected" : ""
            }`}
          >
            <p id="bold"> MEET</p> <p id="light">THIS WAY</p>
            <div className="bottom-line" />
            <HeaderDropdown
              subElements={[
                { link: "Events", text: "EVENTS" },
                { link: "Meetings", text: "MEETINGS" },
              ]}
              onClick={() => setSelected("MEET THIS WAY")}
            />
          </div>
        </a>

        <NavLink to={`/${language}/`}>
          <div
            onClick={() => setSelected("HOME")}
            className="header-element logo"
          />
        </NavLink>
        <NavLink to={`/${language}/WhyThisWay`}>
          <div
            onClick={() => setSelected("WHY THIS WAY")}
            className={`header-element border ${
              selected === "WHY THIS WAY" ? "selected" : ""
            }`}
          >
            <p id="bold"> WHY </p>
            <p id="light">THIS WAY</p>
            <div className="bottom-line" />
            <HeaderDropdown
              smaller
              subElements={[
                { link: "WhyThisWay", text: "BENEFITS" },
                { link: "GalleryTour", text: "GALLERY & VIRTUAL TOUR" },
              ]}
              onClick={() => setSelected("WHY THIS WAY")}
            />
          </div>
        </NavLink>
        <NavLink to={`/${language}/AboutThisWay`}>
          <div
            onClick={() => setSelected("ABOUT THIS WAY")}
            className={`header-element ${
              selected === "ABOUT THIS WAY" ? "selected" : ""
            }`}
          >
            <p id="bold">ABOUT</p> <p id="light">THIS WAY</p>
            <div className="bottom-line" />
            <HeaderDropdown
              subElements={[
                { link: "AboutThisWay", text: "THIS IS US" },
                { link: "Stories", text: "STORIES" },
                { link: "Locations", text: "LOCATIONS" },
              ]}
              onClick={() => setSelected("WHY THIS WAY")}
            />
          </div>
        </NavLink>
      </div>
      {/* <div className="languages-container">
        <div
          className={`language-element ${
            window.localStorage.getItem("code") === "en" ? "selected" : ""
          }`}
          onClick={() => {
            setLanguage("en");
            setLanguageToLocalStorage("en");
            setLocale("en");
            const path =
              window.location.pathname.split("/").slice(2).join("/") || "";
            if (path) {
              history.push(`/en/${path}`);
            } else {
              history.push(`/en`);
            }
            window.location.reload();
          }}
        >
          EN
        </div>
        <div
          className={`language-element ${
            window.localStorage.getItem("code") === "bg" ? "selected" : ""
          }`}
          onClick={() => {
            setLanguage("bg");
            setLanguageToLocalStorage("bg");
            setLocale("bg");
            const path =
              window.location.pathname.split("/").slice(2).join("/") || "";
            if (path) {
              history.push(`/bg/${path}`);
            } else {
              history.push(`/bg`);
            }
            window.location.reload();
          }}
        >
          BG
        </div>
      </div> */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch(setLanguage(language)),
  setLanguageToLocalStorage: (payload) =>
    dispatch(setLanguageToLocalStorage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);
