import { StoryTypes } from "../actions";
import firstStoryImage from "../assets/storyImage/story-1.jpg";
const initialState = {
  currentStory: {
    title: "Green Wall – Not Just a Decoration!",
    text: [
      `When I first stepped into THIS WAY for my job interview, I was very
      impressed by the design, the fit out of the space, the colors that
      flow and complement each other but the most impressive thing
      especially the live Green Wall next to reception.`,
      `Now I work next to this beautiful wall every day, full of different plants, some of which are blooming, others cascading over the next ones. I didn’t realize, until I started writing this article, the immense amount of care that they provide to all of us every day. It is a gentle, almost invisible, care which need to be brought under the bright lights. The name is Biophilic design and no, I’m not going to bore you to death with terms, facts or statistics, that’s not the point. The simple meaning is that, as a human, every one of us is carrying an attraction to nature in all of its forms and expressions.`,
      `When we want to go on holiday, no matter short or long, we think about activities like laying under the sun on a nice beach, hiking in mountains, walking around national parks and searching for the greatest views to admire and stamp into our minds forever. All of this activities and places are connected with nature. We are drawn towards nature for a rest because waking up with ocean view or being surrounded by lush forests and plants is so relaxing and therapeutic for our mental health.`,
      `Nowadays, we are so busy with work, home tasks, worries about almost everything, that we just walk around the cities, overstressed and mentally exhausted. Here comes the mission of Biophilic design which can be easily implemented inside and out. That’s why the Green Wall in THIS WAY is not just for decoration, it serves an important purpose. It cleans the air and helps to reduce stress and anxiety levels, creates a balance between our work life and mental health. There are many studies that prove the benefits of live plants in the workplace. They increase creativity, help with focus, lower stress and anxiety, and also provide a great conversation starter.`,
      `So, I encourage everyone to implement few breaks for a cup of coffee and while drinking ittime to admire just observe the Green Wall and reenergize yourself for the rest of the day. Take thatImplement that moment of calm and reflection into your daily routine  small care daily, because mental health is important, and but the most important is that you will feel amazing with just that small change.`,
      `That’s why the Green Wall in THIS WAY is not only just an interior peace piece of interior decoration, but ait is vital part of the atmosphere and the wellbeing of all of us.`,
      `Reenergize THIS WAY!`,
    ],
    image: firstStoryImage,
    author: "By Daniela",
  },
};

const stories = (state = initialState, { type, payload }) => {
  switch (type) {
    case StoryTypes.SET_STORY:
      return { ...state, currentStory: payload };
    default:
      return state;
  }
};

export default stories;
