import React, { useState, useEffect } from "react";
import {
  TitleComponent,
  TabSwitch,
  ViewMoreComponent,
  DropdownMobile,
} from "../../components";
import { translate } from "../../translations/localization";
import { GalleryModal } from "./GalleryModal";
import "./styles.scss";

const details = [
  "/galleryModalPhotos/Details/-3.jpg",
  "/galleryModalPhotos/Details/-2.JPG",
  "/galleryModalPhotos/Details/-1.JPG",
  "/galleryModalPhotos/Details/0.JPG",
  "/galleryModalPhotos/Details/1.JPG",
  "/galleryModalPhotos/Details/2.JPG",
  "/galleryModalPhotos/Details/3.JPG",
  "/galleryModalPhotos/Details/4.JPG",
  "/galleryModalPhotos/Details/5.JPG",
  "/galleryModalPhotos/Details/6.jpg",
  "/galleryModalPhotos/Details/7.JPG",
  "/galleryModalPhotos/Details/8.JPG",
  "/galleryModalPhotos/Details/9.JPG",
  "/galleryModalPhotos/Details/10.JPG",
  "/galleryModalPhotos/Details/11.jpg",
  "/galleryModalPhotos/Details/12.jpg",
  "/galleryModalPhotos/Details/13.jpg",
  "/galleryModalPhotos/Details/14.jpg",
  "/galleryModalPhotos/Details/15.JPG",
  "/galleryModalPhotos/Details/16.JPG",
  "/galleryModalPhotos/Details/17.jpeg",
  "/galleryModalPhotos/Details/18.jpg",
];

const events = [
  "/galleryModalPhotos/Events/-2.jpg",
  "/galleryModalPhotos/Events/-1.jpg",
  "/galleryModalPhotos/Events/0.jpeg",
  "/galleryModalPhotos/Events/1.JPG",
  "/galleryModalPhotos/Events/2.JPG",
  "/galleryModalPhotos/Events/3.JPG",
  "/galleryModalPhotos/Events/4.jpg",
  "/galleryModalPhotos/Events/5.jpg",
  "/galleryModalPhotos/Events/6.jpg",
  "/galleryModalPhotos/Events/7.jpg",
  "/galleryModalPhotos/Events/8.jpeg",
];

const MeetingRooms = [
  "/galleryModalPhotos/MeetingRooms/-3.jpg",
  "/galleryModalPhotos/MeetingRooms/-2.jpg",
  "/galleryModalPhotos/MeetingRooms/-1.jpg",
  "/galleryModalPhotos/MeetingRooms/0.jpg",
  "/galleryModalPhotos/MeetingRooms/1.jpg",
  "/galleryModalPhotos/MeetingRooms/2.JPG",
  "/galleryModalPhotos/MeetingRooms/3.jpg",
  "/galleryModalPhotos/MeetingRooms/4.jpg",
  "/galleryModalPhotos/MeetingRooms/5.jpg",
  "/galleryModalPhotos/MeetingRooms/6.jpg",
  "/galleryModalPhotos/MeetingRooms/7.jpg",
  "/galleryModalPhotos/MeetingRooms/8.jpg",
  "/galleryModalPhotos/MeetingRooms/9.JPG",
];

const offices = [
  "/galleryModalPhotos/Offices/-3.jpg",
  "/galleryModalPhotos/Offices/-2.jpg",
  "/galleryModalPhotos/Offices/-1.jpg",
  "/galleryModalPhotos/Offices/0.jpg",
  "/galleryModalPhotos/Offices/1.JPG",
  "/galleryModalPhotos/Offices/2.JPG",
  "/galleryModalPhotos/Offices/3.jpg",
  "/galleryModalPhotos/Offices/4.jpg",
  "/galleryModalPhotos/Offices/5.jpg",
  "/galleryModalPhotos/Offices/6.jpg",
  "/galleryModalPhotos/Offices/7.jpg",
  "/galleryModalPhotos/Offices/8.JPG",
];

const sharedSpace = [
  "/galleryModalPhotos/SharedSpace/-3.jpg",
  "/galleryModalPhotos/SharedSpace/-2.jpg",
  "/galleryModalPhotos/SharedSpace/-1.jpg",
  "/galleryModalPhotos/SharedSpace/0.jpg",
  "/galleryModalPhotos/SharedSpace/1.JPG",
  "/galleryModalPhotos/SharedSpace/2.JPG",
  "/galleryModalPhotos/SharedSpace/3.JPG",
  "/galleryModalPhotos/SharedSpace/4.JPG",
  "/galleryModalPhotos/SharedSpace/5.JPG",
  "/galleryModalPhotos/SharedSpace/6.JPG",
  "/galleryModalPhotos/SharedSpace/7.JPG",
  "/galleryModalPhotos/SharedSpace/8.JPG",
  "/galleryModalPhotos/SharedSpace/9.JPG",
  "/galleryModalPhotos/SharedSpace/10.JPG",
  "/galleryModalPhotos/SharedSpace/11.JPG",
  "/galleryModalPhotos/SharedSpace/12.jpg",
  "/galleryModalPhotos/SharedSpace/13.jpg",
  "/galleryModalPhotos/SharedSpace/14.jpg",
  "/galleryModalPhotos/SharedSpace/15.jpg",
  "/galleryModalPhotos/SharedSpace/16.jpg",
  "/galleryModalPhotos/SharedSpace/17.jpg",
  "/galleryModalPhotos/SharedSpace/18.jpg",
  "/galleryModalPhotos/SharedSpace/19.jpg",
  "/galleryModalPhotos/SharedSpace/20.JPG",
  "/galleryModalPhotos/SharedSpace/21.JPG",
  "/galleryModalPhotos/SharedSpace/22.jpg",
  "/galleryModalPhotos/SharedSpace/23.JPG",
];

function GalleryComponent({ onClick }) {
  const [text, setText] = useState(translate("gallerySubText1"));
  const [categoryImages, setCategoryImages] = useState(sharedSpace);
  const [index, setIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const [touchYStart, setTouchYStart] = useState(0);
  const [touchYEnd, setTouchYEnd] = useState(0);
  const [initialImage, setInitialImage] = useState(null);
  const [initialCategory, setInitialCategory] = useState(null);
  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [open]);

  useEffect(() => {
    if (Math.abs(touchYStart - touchYEnd) < 100) {
      if (touchStart < touchEnd) {
        if (index > 0) {
          setIndex((prev) => prev - 1);
        } else {
          setIndex(categoryImages.length - 1);
        }
      } else {
        if (index < categoryImages.length - 1) {
          setIndex((prev) => prev + 1);
        } else {
          setIndex(0);
        }
      }
    }
  }, [touchEnd]);

  const chosenLanguage = localStorage.getItem("code");
  return (
    <div className="gallery-container">
      {open && window.innerWidth > 900 && (
        <GalleryModal
          onClose={() => {
            setInitialImage(null);
            setOpen(false);
          }}
          initialCategory={initialCategory}
          initialImage={initialImage}
        />
      )}
      <div className="left-container">
        <div className="text-container">
          <TitleComponent title1={translate("galleryTitle")} />
          {window.innerWidth < 900 ? (
            <>
              <div
                className="selected-gallery-image"
                style={{ backgroundImage: `url(${categoryImages[index]})` }}
                onTouchStart={(event) => {
                  setTouchStart(event.changedTouches[0].clientX);
                  setTouchYStart(event.changedTouches[0].clientY);
                }}
                onTouchEnd={(event) => {
                  setTouchEnd(event.changedTouches[0].clientX);
                  setTouchYEnd(event.changedTouches[0].clientY);
                }}
              />
              <DropdownMobile
                selectedOption={translate("gallerySubTitle1")}
                options={[
                  translate("gallerySubTitle1"),
                  translate("gallerySubTitle2"),
                  translate("gallerySubTitle3"),
                  translate("gallerySubTitle4"),
                  translate("gallerySubTitle5"),
                ]}
                onClick={(value) => {
                  switch (value) {
                    case translate("gallerySubTitle1"):
                      setIndex(0);
                      setText(translate("gallerySubText1"));
                      setCategoryImages(sharedSpace);
                      break;
                    case translate("gallerySubTitle2"):
                      setIndex(1);
                      setText(translate("gallerySubText2"));
                      setCategoryImages(MeetingRooms);
                      break;
                    case translate("gallerySubTitle3"):
                      setIndex(2);
                      setText(translate("gallerySubText3"));
                      setCategoryImages(offices);
                      break;
                    case translate("gallerySubTitle4"):
                      setIndex(3);
                      setText(translate("gallerySubText4"));
                      setCategoryImages(details);
                      break;
                    case translate("gallerySubTitle5"):
                      setIndex(0);
                      setText(translate("gallerySubText5"));
                      setCategoryImages(events);
                      break;
                    default:
                      break;
                  }
                }}
              />
            </>
          ) : (
            <TabSwitch
              special
              selected={translate("gallerySubTitle1")}
              buttons={[
                translate("gallerySubTitle1"),
                translate("gallerySubTitle2"),
                translate("gallerySubTitle3"),
                translate("gallerySubTitle4"),
                translate("gallerySubTitle5"),
              ]}
              onSelect={(value) => {
                if (!open) {
                  switch (value) {
                    case translate("gallerySubTitle1"):
                      setCategoryImages(sharedSpace);
                      setText(translate("gallerySubText1"));
                      break;
                    case translate("gallerySubTitle2"):
                      setCategoryImages(MeetingRooms);
                      setText(translate("gallerySubText2"));
                      break;
                    case translate("gallerySubTitle3"):
                      setCategoryImages(offices);
                      setText(translate("gallerySubText3"));
                      break;
                    case translate("gallerySubTitle4"):
                      setCategoryImages(details);
                      setText(translate("gallerySubText4"));
                      break;
                    case translate("gallerySubTitle5"):
                      setCategoryImages(events);
                      setText(translate("gallerySubText5"));
                      break;
                    default:
                      break;
                  }
                }
              }}
            />
          )}

          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {text}
          </p>
          <ViewMoreComponent
            textBold={translate("galleryButton1")}
            textLight={translate("galleryButton2")}
            arrowDown
            right
            onClick={onClick}
          />
        </div>
      </div>
      {window.innerWidth > 900 && (
        <div className="right-container">
          {categoryImages.slice(0, 4).map((image) => {
            return (
              <div
                className="image"
                style={{ backgroundImage: `url(${image})` }}
                onClick={() => {
                  window.scrollTo({ top: 0, behavior: "smooth" });
                  setInitialCategory(categoryImages);
                  setInitialImage(image);
                  setOpen(true);
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}

export default GalleryComponent;
