import React, { useRef } from "react";
import {
  MainTextComponent,
  TextSelectionComponent,
  ContactForm,
} from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

const content = [
  {
    title1: translate("workTitle1"),
    title2: translate("workTitle2"),
    text: [
      {
        subtitle: translate("workSubTitle1"),
        text: translate("workText1"),
        text2: "Fully furnished, equipped and serviced",
        additionalText: [
          `- 33 turnkey private offices with 1 to 12 workstations`,
          `- Access to 700 sq.m of shared spaces including  lounge & café areas, kitchenette, meeting booths, phone booths, shared library, coworking desks and soft
          seating areas for iInformal meetings, networking or coffee breaks`,
          `- 24/7 access`,
          `- This Way reception and team support (Monday - Friday 9am-6pm)`,
          `- On demand access to, fully equipped meeting rooms which can be booked through the App`,
        ],
        additionalInfo: [
          {
            textBold: translate("buttonText5"),
            textLight: translate("buttonText6"),
          },
          {
            textBold: translate("buttonText3"),
            textLight: translate("buttonText4"),
            link: "https://mpembed.com/show/?m=UsxETWZnxaW",
          },
        ],
      },
    ],
    video:
      "https://thiswaywebsite.s3.eu-central-1.amazonaws.com/videos/serviced/playlist.m3u8",
  },
  {
    title1: "COWORKING",
    title2: "",
    text: [
      {
        subtitle: translate("workSubTitle2"),
        text: translate("workText2"),
        additionalText: [
          `- 24 workstations to choose from across the open space`,
          `- Agility to choose the kind of workspace to suit your needs – access to coworking desks,
          phone booths, meeting pods, window high seats, café area`,
          `- Access Monday-Friday 9am-6pm*`,
          `- Daily Reception and team support (Monday-Friday; 9am-6pm)`,
        ],
        additionalInfo: [
          {
            textBold: translate("buttonText5"),
            textLight: translate("buttonText6"),
          },
          {
            textBold: translate("buttonText3"),
            textLight: translate("buttonText4"),
            link: "https://mpembed.com/show/?m=UsxETWZnxaW",
          },
        ],
      },
    ],
    video:
      "https://thiswaywebsite.s3.eu-central-1.amazonaws.com/videos/coworking/playlist.m3u8",
  },
  {
    title1: "HYBRID",
    title2: "WORKSPACE",
    text: [
      {
        subtitle: translate("workSubTitle3"),
        text: translate("workText3"),
        additionalText: [
          `- Create a solution to suit your unique business needs`,
          `- Have the agility to expand as you need to`,
          `- Combine dedicated office space with coworking desks and meeting room hours as per your
          demand`,
          `- Provide your team with a variety of options to work, meet and create from`,
          `- Ensure a great and productive working environment`,
          `- Fully equipped, furnished and serviced space available for easy expansion`,
        ],
        additionalInfo: [
          {
            textBold: translate("buttonText5"),
            textLight: translate("buttonText6"),
          },

          {
            textBold: translate("buttonText3"),
            textLight: translate("buttonText4"),
            link: "https://mpembed.com/show/?m=UsxETWZnxaW",
          },
        ],
      },
    ],
    video:
      "https://thiswaywebsite.s3.eu-central-1.amazonaws.com/videos/hybridModel/playlist.m3u8",
  },
];

const content2 = [
  {
    title1: translate("officeDetails1"),
    title2: translate("officeDetails2"),
    innerContent: [
      {
        subtitle: translate("workSubTitle4"),
        text: translate("workText4"),
      },
      {
        subtitle: translate("workSubTitle5"),
        text: translate("workText5"),
      },
      {
        subtitle: translate("workSubTitle6"),
        text: translate("workText6"),
      },
      {
        subtitle: translate("workSubTitle7"),
        text: translate("workText7"),
      },
      {
        subtitle: translate("workSubTitle8"),
        text: translate("workText8"),
      },
      {
        subtitle: translate("workSubTitle9"),
        text: translate("workText9"),
      },
    ],
    additionalInfo: [
      {
        textBold: translate("buttonText5"),
        textLight: translate("buttonText6"),
        link: "https://mpembed.com/show/?m=UsxETWZnxaW",
      },
    ],
    images: [
      "/imgs/flexible-workspace.jpg",
      "/imgs/33-offices.jpg",
      "/imgs/Tailored-Space.jpg",
      "/imgs/Coworking-Facilities.jpg",
      "/imgs/meeting-spaces.jpg",
      "/imgs/unique.jpg",
    ],
  },
  {
    title1: translate("workTitle3"),
    title2: translate("workTitle4"),
    innerContent: [
      {
        subtitle: translate("workSubTitle15"),
        text: translate("workText10"),
      },
      {
        subtitle: translate("workSubTitle11"),
        text: [
          "-High speed internet, online meeting room booking system, touchless integrated access  control, community app, tailored IT solutions available (fees apply)",
          "- Zero initial investment",
          "- No fit - out costs",
          "- No set up or initiation fees",
          "- Save time",
          "- Move in and be operational Instantly",
        ],
      },
      {
        subtitle: translate("workSubTitle14"),
        text: [
          "- Building security and management",
          "- Car and bicycle parking (fees apply)",
          "- Daily cleaning, disinfection and maintenance",
          "- Showers",
          "- Caféteria restaurant with indoor and outdoor seating",
          "- Nearby facilities",
          "- The Mall, Shell, Next Level Fitness, Novotel, 10 minutes away from Sofia International Airport",
        ],
      },
    ],
    additionalInfo: [],
    images: [
      "/imgs/all-the-utilities.jpg",
      "/imgs/Our-team.jpg",
      "/imgs/building-bonuses.jpg",
    ],
  },
];

function WorkThisWay() {
  const contactUs = useRef(null);
  return (
    <div className="work-this-way-container">
      {content.map((element, index) => (
        <MainTextComponent
          noMarginParagraph
          switched
          right={index % 2 === 1}
          content={element}
          video={element.video}
          noFlex
          onScroll={() =>
            contactUs.current.scrollIntoView({
              block: "center",
              inline: "center",
              behavior: "smooth",
            })
          }
        />
      ))}
      {content2.map((element, index) => (
        <TextSelectionComponent
          faster
          small={index === 1}
          content={element}
          right={index % 2 === 0}
          images={element.images}
          short={element.innerContent.length < 6}
          onScroll={() =>
            contactUs.current.scrollIntoView({
              block: "center",
              inline: "center",
              behavior: "smooth",
            })
          }
        />
      ))}
      <div ref={contactUs}>
        <ContactForm />
      </div>
    </div>
  );
}

export default WorkThisWay;
