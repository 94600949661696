import { LanguageTypes } from '../actions'

let lang = window.localStorage.getItem('code')

const defaultState = {
  selectedLanguage: lang || 'bg',
  languages: [],
}

const language = (state = defaultState, action) => {
  switch (action.type) {
    case LanguageTypes.SET_LANGUAGE:
      window.localStorage.setItem('code', action.payload)
      return{
        ...state,
        selectedLanguage: action.payload
        }
    case LanguageTypes.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        languages: [...action.payload]
      }
    default:
      return state
  }
}

export default language
