import React from "react";
import "./styles.scss";

function BlackGridComponent({ mainText, subText, img }) {
  return (
    <div className="black-grid-container">
      <div className="text-container">
        <p>{mainText}</p>
        <p>{subText}</p>
      </div>
      <div className="logo" style={{ backgroundImage: `url(${img})` }} />
    </div>
  );
}

export default BlackGridComponent;
