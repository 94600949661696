import React from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import "./styles.scss";

const styles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export class GoogleMaps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: {},
    };
  }

  componentDidMount() {
    this.setState({ location: this.props.location });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.lat !== this.props.location.lat ||
      prevProps.location.lng !== this.props.location.lng
    ) {
      this.setState({ location: this.props.location });
    }
  }

  render() {
    if (!this.props.loaded) return <div>Loading...</div>;

    return (
      <div className="map-wrap">
        <Map
          className={"map"}
          google={this.props.google}
          zoom={window.innerWidth < 900 ? 11 : 13}
          center={this.state.location}
          styles={styles}
        >
          <Marker
            icon={{ url: "/icons/iconog.svg" }}
            position={{ lat: 42.66267591155627, lng: 23.380816440601293 }}
          />
          {/* <Marker
            icon={{ url: "/icons/sterling-logo.svg" }}
            position={{ lat: 42.696472922955834, lng: 23.323773003089357 }}
          /> */}
          <Marker
            icon={{ url: "/icons/sterling-logo.svg" }}
            position={{ lat: 42.692053866726255, lng: 23.354412325258266 }}
          />
          <Marker
            icon={{ url: "/icons/sterling-logo.svg" }}
            position={{ lat: 41.06886773069345, lng: 29.00577735605992 }}
          />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyALipb3WN32r90_zyf2wk-vNrEod_JdR5Q",
})(GoogleMaps);
