import { combineReducers } from "redux";
// import { createNavigationReducer } from 'react-navigation-redux-helpers'
import { routerReducer } from "react-router-redux";

import starter from "./starter";
import language from "./language";
import stories from "./stories";

export default combineReducers({
  starter,
  routerReducer,
  language,
  stories,
});
