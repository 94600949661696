import React, { useState } from "react";
import { connect } from "react-redux";
import { history } from "../../config/stores";
import { useLocation } from "react-router-dom";
import "./styles.scss";

function Footer({ language }) {
  const [newsletterValue, setnewsletterValue] = useState("");
  const [sentNewsletter, setSentNewsletter] = useState(false);
  const location = useLocation();
  return (
    <div
      className={`footer-container ${
        location.pathname.includes("PrivacyPolicy") && "display-none"
      }`}
    >
      <div className="footer-inner-container">
        <div className="footer-column">
          <span
            id="bold"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/WorkThisWay`);
            }}
          >
            WORK
          </span>
          <span
            id="light"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/WorkThisWay`);
            }}
          >
            {" "}
            THIS WAY
          </span>
          <p
            onClick={() => {
              window.scrollTo({
                top: 100,
                behavior: "smooth",
              });
              history.push(`/${language}/WorkThisWay`);
            }}
          >
            SERVICED OFFICES
          </p>
          <p
            onClick={() => {
              window.scrollTo({
                top: window.innerHeight + 100,
                behavior: "smooth",
              });
              history.push(`/${language}/WorkThisWay`);
            }}
          >
            COWORKING
          </p>
          <p
            onClick={() => {
              window.scrollTo({
                top: window.innerHeight * 2 + 100,
                behavior: "smooth",
              });
              history.push(`/${language}/WorkThisWay`);
            }}
          >
            HYBRID WORKSPACES
          </p>
          <p
            onClick={() => {
              window.scrollTo({
                top: window.innerHeight * 3 + 100,
                behavior: "smooth",
              });
              history.push(`/${language}/WorkThisWay`);
            }}
          >
            OFFICE DETAILS
          </p>
          <p
            onClick={() => {
              window.scrollTo({
                top: window.innerHeight * 4 + 100,
                behavior: "smooth",
              });
              history.push(`/${language}/WorkThisWay`);
            }}
          >
            INCLUDED SERVICES
          </p>
        </div>
        <div className="footer-column">
          <span
            id="bold"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/Events`);
            }}
          >
            MEET
          </span>
          <span
            id="light"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/Events`);
            }}
          >
            {" "}
            THIS WAY
          </span>
          <p
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/Events`);
            }}
          >
            EVENTS
          </p>
          <p
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/Meetings`);
            }}
          >
            MEETINGS
          </p>
        </div>
        <div className="footer-column">
          <span
            id="bold"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/WhyThisWay`);
            }}
          >
            WHY
          </span>
          <span
            id="light"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/WhyThisWay`);
            }}
          >
            {" "}
            THIS WAY
          </span>
          <p
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/WhyThisWay`);
            }}
          >
            BENEFITS {"&"} ADVANTAGES
          </p>
          <p
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/GalleryTour`);
            }}
          >
            GALLERY {"&"} VIRTUAL TOUR
          </p>
        </div>
        <div className="footer-column">
          <span
            id="bold"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/AboutThisWay`);
            }}
          >
            ABOUT
          </span>
          <span
            id="light"
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/AboutThisWay`);
            }}
          >
            {" "}
            THIS WAY
          </span>
          <p
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/AboutThisWay`);
            }}
          >
            THIS IS US
          </p>
          <p
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/Stories`);
            }}
          >
            STORIES
          </p>
          <p
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/Locations`);
            }}
          >
            LOCATIONS
          </p>
        </div>
        <div className="footer-column large">
          <span id="bold">REACH</span>
          <span id="light"> OUT AT</span>
          <div className="flex-container">
            <div
              className="social-icon facebook"
              onClick={() =>
                window.open("https://www.facebook.com/thisway.coworking")
              }
            />
            <div
              className="social-icon instagram"
              onClick={() =>
                window.open("https://www.instagram.com/thiswaysofia/")
              }
            />
            <div
              className="social-icon linkedin"
              onClick={() =>
                window.open("https://www.linkedin.com/company/workthisway/")
              }
            />
            <div
              className="social-icon youtube"
              onClick={() =>
                window.open(
                  "https://www.youtube.com/channel/UCD_6OK3_nBH6mbRsrl0IBtw"
                )
              }
            />
          </div>
          <p id="small">E: info@thisway.com.</p>
          <p id="small">T: +359 2 9264264</p>
          <p id="small">A: 4th Floor, Office A, 2 Ivan Abadjiev Street, </p>
          <p id="small">Sofia, Bulgaria</p>
          <div className="footer-column-inner">
            <span id="bold">OUR</span>
            <span id="light"> NEWSLETTER</span>
            <div className="input-wrapper">
              <input
                className="form-input"
                type="text"
                value={
                  sentNewsletter
                    ? "Thank you! You are subscribed!"
                    : newsletterValue
                }
                placeholder="Your email*"
                onChange={(event) => setnewsletterValue(event.target.value)}
              />
              <div
                className={`input-arrow-icon ${sentNewsletter && "sent"}`}
                onClick={() => !sentNewsletter && setSentNewsletter(true)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom-container">
        <div className="footer-bottom-inner-container">
          <p onClick={() => window.open(`/${language}/PrivacyPolicy`)}>
            PRIVACY POLICY
          </p>
          <p
            onClick={() => {
              if (language === "bg") {
                window.open("/docs/THIS_WAY_draft_Cookies_Policy_BG.pdf");
              } else {
                window.open("/docs/Draft_Cookies_Policy_05.04.2021.pdf");
              }
            }}
          >
            COOKIES
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.selectedLanguage,
});

export default connect(mapStateToProps, null)(Footer);
