import React from "react";
import { ViewMoreComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function WhiteGridComponent({ title, text, img, larger, onClick }) {
  return (
    <div className="white-grid-container">
      <div
        className={`logo-icon ${larger ? "larger" : ""}`}
        style={{ backgroundImage: `url(${img})` }}
      />
      {title && <div className="title">{title}</div>}
      <div className="text">{text}</div>
      <ViewMoreComponent
        textBold={translate("readMore1")}
        textLight={translate("readMore2")}
        right
        onClick={onClick}
      />
    </div>
  );
}

export default WhiteGridComponent;
