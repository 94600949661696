import React, { useState, useEffect } from "react";

import "./styles.scss";

function TabSwitch({ buttons, selected, whiteTab, onSelect, special }) {
  const [selectedTab, setSelectedTab] = useState(selected);
  const [tabIndex, setTabIndex] = useState(0);
  const chosenLanguage = localStorage.getItem("code");
  useEffect(() => {
    const next = (tabIndex + 1) % buttons.length;
    const id = setTimeout(() => {
      setSelectedTab(buttons[next]);
      setTabIndex(next);
      onSelect(buttons[next]);
    }, 5000);
    return () => clearTimeout(id);
  }, [tabIndex, buttons, onSelect]);
  return (
    <div className={`tab-switch-container ${whiteTab ? "white" : ""}`}>
      {buttons.map((button, index) => (
        <div
          style={
            (chosenLanguage === "bg" && {
              fontFamily: selectedTab === button ? "bold" : "regular",
            }) ||
            {}
          }
          className={`button ${special && "smallest"} ${
            selectedTab === button ? "selected" : ""
          } ${index === 0 || index === buttons.length - 1 ? "no-margin" : ""} ${
            buttons.length < 3 && index === 0 ? "special" : ""
          } ${whiteTab ? "white" : ""} ${
            index === 0 && buttons.length > 2 ? "first" : ""
          } ${
            index === buttons.length - 1 && buttons.length > 2 ? "last" : ""
          }`}
          onClick={() => {
            setTabIndex(index);
            setSelectedTab(button);
            onSelect(button);
          }}
        >
          {button}
        </div>
      ))}
    </div>
  );
}

export default TabSwitch;
