import React, { useState } from "react";
import { TitleComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

const images = [
  "/imgs/saborna-min.jpg",
  "/imgs/istanbul.jpg",
  "/imgs/serdika.jpg",
  "/imgs/Coworking-Facilities.jpg",
  "/imgs/Coworking-Facilities.jpg",
];

const text = [
  {
    text1: "2004",
    text2: translate("aboutThisWaySubText1"),
  },
  {
    text1: "2008",
    text2: translate("aboutThisWaySubText2"),
  },
  {
    text1: "2015",
    text2: translate("aboutThisWaySubText3"),
  },
  {
    text1: "2019",
    text2: translate("aboutThisWaySubText4"),
  },
  {
    text1: "2021",
    text2: translate("aboutThisWaySubText5"),
  },
];

function AboutSwitchComponent() {
  const [tab, setTab] = useState(1);
  const chosenLanguage = localStorage.getItem("code");
  return (
    <div className="about-switch-container">
      <div
        className="left-container"
        style={{ backgroundImage: `url(${images[tab]})` }}
      />
      <div className="right-container">
        <div className="text-container">
          <TitleComponent title1="OUR" title2="HISTORY" />
          <p
            style={
              (chosenLanguage === "bg" && {
                fontFamily: "thin",
                lineHeight: 1.1,
              }) ||
              {}
            }
          >
            {translate("aboutThisWayText3")}
          </p>

          <div className="flex-column">
            <div
              className={`tab-element ${tab === 0 ? "selected" : ""}`}
              style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}
              onClick={() => setTab(0)}
            >
              2004 {translate("aboutThisWaySubText1")}
            </div>
            <div
              className={`tab-element ${tab === 1 ? "selected" : ""}`}
              style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}
              onClick={() => setTab(1)}
            >
              2008 {translate("aboutThisWaySubText2")}
            </div>
            <div
              className={`tab-element ${tab === 2 ? "selected" : ""}`}
              style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}
              onClick={() => setTab(2)}
            >
              2015 {translate("aboutThisWaySubText3")}
            </div>
            <div
              className={`tab-element ${tab === 3 ? "selected" : ""}`}
              style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}
              onClick={() => setTab(3)}
            >
              2019 {translate("aboutThisWaySubText4")}
            </div>
            <div
              className={`tab-element ${tab === 4 ? "selected" : ""}`}
              style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}
              onClick={() => setTab(4)}
            >
              2021 {translate("aboutThisWaySubText5")}
            </div>
          </div>
        </div>
      </div>
      {window.innerWidth < 900 &&
        images.map((image, index) => {
          return (
            <>
              {index === images.length - 1 ? null : (
                <div
                  className="about-us-image"
                  style={{ backgroundImage: `url(${image})` }}
                />
              )}
              <div className="black-container">
                <h3>{text[index].text1}</h3>
                <p>{text[index].text2}</p>
              </div>
            </>
          );
        })}
    </div>
  );
}

export default AboutSwitchComponent;
