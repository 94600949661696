import React from "react";
import {
  MainStoryComponent,
  StoriesSubComponent,
  ContactForm,
} from "../../components";
import "./styles.scss";

function Stories() {
  return (
    <div className="stories-container">
      <MainStoryComponent />
      <StoriesSubComponent />
      <ContactForm reversed={window.innerWidth < 900 ? false : true} />
    </div>
  );
}

export default Stories;
