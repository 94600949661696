import React, { useRef } from "react";
import { GalleryComponent, TourComponent, ContactForm } from "../../components";
import "./styles.scss";

function GalleryTour() {
  const tourRef = useRef(null);
  return (
    <div className="gallery-tour-container">
      <GalleryComponent
        onClick={() => {
          tourRef.current &&
            tourRef.current.scrollIntoView({
              block: "center",
              inline: "center",
              behaviour: "smooth",
            });
        }}
      />
      <TourComponent
        title="TOUR"
        title2="THIS WAY"
        img="/imgs/Tour.png"
        dimmed
        selector={tourRef}
      />
      <ContactForm />
    </div>
  );
}

export default GalleryTour;
