import React, { useRef } from "react";
import {
  MeetingsComponentWhite,
  MeetingsComponentBlack,
  TourComponent,
  ContactForm,
} from "../../components";
import "./styles.scss";

function Meetings() {
  const contactUs = useRef(null);
  return (
    <div className="meetings-container">
      <MeetingsComponentWhite
        onScroll={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      <MeetingsComponentBlack
        onScroll={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      <TourComponent
        title="TOUR"
        title2="THE MEETING ROOMS"
        img="/imgs/Tour-meeting-rooms.png"
        smaller
      />
      <div ref={contactUs}>
        <ContactForm />
      </div>
    </div>
  );
}

export default Meetings;
