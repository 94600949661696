import React, { useState } from "react";
import {
  TabSwitch,
  ViewMoreComponent,
  TitleComponent,
  DropdownMobile,
} from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

const images = [
  "/imgs/contacts-min.jpg",
  "/imgs/after-hours.jpg",
  "/imgs/training.png",
  "/imgs/Wellbeing.png",
];

function EventsComponentWhite({ onScroll }) {
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedText, setSelectedText] = useState(translate("eventsTabText1"));
  const chosenLanguage = localStorage.getItem("code");
  return (
    <div
      className="events-white-container"
      style={{ backgroundImage: `url(${images[imageIndex]})` }}
    >
      <div className="overlay-white">
        <div className="content-container">
          <TitleComponent title1="EVENTS" title2="THIS WAY" />
          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {translate("eventsText1")}
          </p>
          {window.innerWidth < 900 ? (
            <>
              <div
                className="events-mobile-image"
                style={{ backgroundImage: `url(${images[imageIndex]})` }}
              />
              <DropdownMobile
                selectedOption={translate("eventsTab1")}
                options={[
                  translate("eventsTab1"),
                  translate("eventsTab2"),
                  translate("eventsTab3"),
                  translate("eventsTab4"),
                ]}
                onClick={(value) => {
                  switch (value) {
                    case translate("eventsTab1"):
                      setImageIndex(0);
                      setSelectedText(translate("eventsTabText1"));
                      break;
                    case translate("eventsTab3"):
                      setImageIndex(1);
                      setSelectedText(translate("eventsTabText3"));
                      break;
                    case translate("eventsTab2"):
                      setImageIndex(2);
                      setSelectedText(translate("eventsTabText2"));
                      break;
                    case translate("eventsTab4"):
                      setImageIndex(3);
                      setSelectedText(translate("eventsTabText4"));
                      break;
                    default:
                      break;
                  }
                }}
              />
            </>
          ) : (
            <TabSwitch
              selected={translate("eventsTab1")}
              buttons={[
                translate("eventsTab1"),
                translate("eventsTab2"),
                translate("eventsTab3"),
                translate("eventsTab4"),
              ]}
              onSelect={(value) => {
                switch (value) {
                  case translate("eventsTab1"):
                    setImageIndex(0);
                    setSelectedText(translate("eventsTabText1"));
                    break;
                  case translate("eventsTab3"):
                    setImageIndex(1);
                    setSelectedText(translate("eventsTabText3"));
                    break;
                  case translate("eventsTab2"):
                    setImageIndex(2);
                    setSelectedText(translate("eventsTabText2"));
                    break;
                  case translate("eventsTab4"):
                    setImageIndex(3);
                    setSelectedText(translate("eventsTabText4"));
                    break;
                  default:
                    break;
                }
              }}
            />
          )}

          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {selectedText}
          </p>
          <ViewMoreComponent
            textBold={translate("buttonText5")}
            textLight={translate("buttonText6")}
            right
            onClick={() => onScroll()}
          />
        </div>
      </div>
    </div>
  );
}

export default EventsComponentWhite;
