import React, { useEffect, useRef } from "react";
import Hls from "hls.js/dist/hls.js";
import "./styles.scss";

function HLSVideoPlayer({ videoURL, specialVideo }) {
  const videoRef = useRef(null);
  useEffect(() => {
    if (
      videoRef.current &&
      videoRef.current.canPlayType("application/vnd.apple.mpegurl")
    ) {
      videoRef.current.src = videoURL;
    } else if (Hls.isSupported()) {
      var hls = new Hls();
      hls.loadSource(videoURL);
      hls.attachMedia(videoRef.current);
    }
  }, [videoURL]);

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        playsInline={true}
        muted={true}
        preload="auto"
        loop={true}
        autoPlay={true}
        style={{
          maxWidth: `${specialVideo ? "133vw" : "100vw"}`,
          minWidth: `${specialVideo ? "133vw" : "100vw"}`,
          height: window.innerWidth < 900 ? "50vh" : "100%",
          objectFit: "cover",
          position: window.innerWidth < 900 ? "relative" : "absolute",
          top: "0",
          left: `${specialVideo ? "-16.5vw" : "0"}`,
          zIndex: "-100",
          marginBottom: window.innerWidth < 900 ? "-3px" : "",
        }}
      ></video>
    </div>
  );
}

export default HLSVideoPlayer;
