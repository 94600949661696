import React, { useState } from "react";
import { HLSVideoPlayer, TitleComponent } from "../../components";
import "./styles.scss";

function TourComponent({
  title,
  title2,
  text,
  text2,
  video,
  img,
  dimmed,
  smaller,
  selector,
  smallerVideo,
}) {
  const [eventView, setEventView] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const chosenLanguage = localStorage.getItem("code");
  return (
    <>
      <div
        className="tour-container"
        style={video ? {} : { backgroundImage: `url(${img})` }}
        ref={selector}
      >
        {dimmed && <div className="dimmed-overlay" />}
        {video && <HLSVideoPlayer videoURL={video} />}
        {window.innerWidth < 900 && eventView ? (
          <div
            className="exit-event-btn"
            onClick={() => {
              setEventView(false);
            }}
          />
        ) : null}
        {window.innerWidth > 900 && additionalInfo ? (
          <div
            className={`additional-info-container ${
              additionalInfo ? "open" : ""
            }`}
          >
            <div className="text-container">
              <TitleComponent title1="YOGA" title2="THIS WAY" />
              <h3>Information about this event</h3>
              <p>
                Hey there where ya goin’, not exactly knowin’, who says you have
                to call just one place home. He’s goin’ Children of the sun, see
                your time has just begun, searching for your ways, through
                adventures every day.
              </p>
            </div>
          </div>
        ) : null}
        {window.innerWidth > 900 && eventView ? (
          <>
            {additionalInfo ? (
              <div
                className="info-view-back-btn"
                onClick={() => setAdditionalInfo(false)}
              />
            ) : (
              <div
                className="additional-info-button"
                onClick={() => setAdditionalInfo(true)}
              >
                i
              </div>
            )}

            <div
              className="event-view-back-btn"
              onClick={() => {
                setAdditionalInfo(false);
                setEventView(false);
              }}
            />
          </>
        ) : window.innerWidth < 900 && eventView ? null : (
          <>
            <p style={smallerVideo ? { marginBottom: "40px" } : {}}>{text}</p>
            <div className="title-container">
              <h1
                id="bold"
                style={
                  smaller && window.innerWidth < 900 ? { fontSize: "24px" } : {}
                }
                style={
                  (chosenLanguage === "bg" && { fontFamily: "bold" }) || {}
                }
              >
                {title}
              </h1>
              <h1
                id="light"
                style={
                  smaller && window.innerWidth < 900 ? { fontSize: "24px" } : {}
                }
                style={
                  (chosenLanguage === "bg" && { fontFamily: "thin" }) || {}
                }
              >
                {title2}
              </h1>
            </div>
            <p
              id="big"
              style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}
            >
              {text2}
            </p>
            <div
              style={smallerVideo ? { marginTop: "-80px" } : {}}
              className={`button-icon ${
                title === "YOGA" ? "yoga" : "not-yoga"
              }`}
              onClick={() => {
                if (title === "YOGA") {
                  setEventView(true);
                } else if (title === "WELCOME") {
                  window.open(
                    "https://www.youtube.com/watch?v=wSHtqa1k3Wk&feature=emb_title"
                  );
                } else {
                  window.open("https://my.matterport.com/show/?m=NkfFppvNruX");
                }
              }}
            />
          </>
        )}

        <div className="yoga-overlay"></div>
      </div>
      {window.innerWidth < 900 && eventView ? (
        <div className="mobile-event-view-container">
          <div className="text-container">
            <TitleComponent title1="YOGA" title2="THIS WAY" />
            <h3>Information about this event</h3>
            <p>
              Hey there where ya goin’, not exactly knowin’, who says you have
              to call just one place home. He’s goin’ Children of the sun, see
              your time has just begun, searching for your ways, through
              adventures every day.
            </p>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default TourComponent;
