import React, { useRef } from "react";
import {
  MainTextComponent,
  TourComponent,
  GridHomeComponent,
  StoriesHomeComponent,
  LocationsSection,
  HomeGridGallery,
  ContactForm,
} from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

const content = [
  {
    title1: "WORK",
    title2: "THIS WAY",
    text: [
      {
        subtitle: "COWORKING. SERIOUSLY.",
        text: translate("homeText1"),
        additionalInfo: [
          {
            textBold: translate("buttonText1"),
            textLight: translate("buttonText2"),
            page: "WorkThisWay",
          },
          {
            textBold: translate("buttonText3"),
            textLight: translate("buttonText4"),
            link: "https://my.matterport.com/show/?m=NkfFppvNruX",
          },
        ],
      },
    ],
    video:
      "https://thiswaywebsite.s3.eu-central-1.amazonaws.com/videos/work/playlist.m3u8",
  },
  {
    title1: "MEET",
    title2: "THIS WAY",
    text: [
      {
        subtitle: translate("homeSubText1"),
        text: translate("homeText2"),
        additionalInfo: [
          {
            textBold: translate("buttonText0"),
            textLight: translate("buttonText-1"),
            page: `Meetings`,
          },
        ],
      },
      {
        subtitle: translate("homeSubText2"),
        text: translate("homeText3"),
        additionalInfo: [
          {
            textBold: translate("buttonText0"),
            textLight: translate("buttonText-1"),
            page: `Events`,
          },
        ],
      },
    ],
    video:
      "https://thiswaywebsite.s3.eu-central-1.amazonaws.com/videos/meet/playlist.m3u8",
  },
  {
    title1: "FEEL",
    title2: "THIS WAY",
    text: [
      {
        subtitle: translate("homeSubText3"),
        text: translate("homeText4"),
        additionalInfo: [
          {
            textBold: translate("buttonText5"),
            textLight: translate("buttonText6"),
          },
          {
            textBold: translate("buttonText3"),
            textLight: translate("buttonText4"),
          },
        ],
      },
    ],
    video:
      "https://thiswaywebsite.s3.eu-central-1.amazonaws.com/videos/experience/playlist.m3u8",
  },
];

function Home() {
  const contactUs = useRef(null);
  return (
    <div className="home-container">
      {content.map((element, index) => (
        <MainTextComponent
          noFlex
          right={index % 2 === 0}
          content={element}
          video={element.video}
          animation
          id={`effects-${index}`}
          onScroll={() =>
            contactUs.current.scrollIntoView({
              block: "center",
              inline: "center",
              behavior: "smooth",
            })
          }
        />
      ))}
      <TourComponent
        title="TOUR"
        title2="THIS WAY"
        img="/imgs/Tour.png"
        dimmed
      />
      <GridHomeComponent />
      <StoriesHomeComponent />
      <LocationsSection />
      <HomeGridGallery />
      <div ref={contactUs}>
        <ContactForm />
      </div>
    </div>
  );
}

export default Home;
