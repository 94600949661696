import React from "react";
import { TitleComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function BenefitsComponent() {
  const chosenLanguage = localStorage.getItem("code");
  return (
    <div className="benefits-container">
      <div className="left-container">
        <div className="text-container">
          <TitleComponent
            title1="BENEFITS"
            title2="FOR YOUR BUSINESS"
            bulgarianSpecial
          />
          <div className="text-lines-container">
            <div className="flex-container first">
              <div className="thin-line">
                <div className="thick-line" />
              </div>
              <div className="logo-bullet" />
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("benefitsText1")}
              </p>
            </div>
            <div className="flex-container">
              <div className="thin-line">
                <div className="thick-line" />
              </div>
              <div className="logo-bullet" />
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("benefitsText2")}
              </p>
            </div>
            <div className="flex-container">
              <div className="thin-line">
                <div className="thick-line" />
              </div>
              <div className="logo-bullet" />
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("benefitsText3")}
              </p>
            </div>
            <div className="flex-container">
              <div className="thin-line">
                <div className="thick-line" />
              </div>
              <div className="logo-bullet" />
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("benefitsText4")}
              </p>
            </div>
            <div className="flex-container">
              <div className="thin-line">
                <div className="thick-line" />
              </div>
              <div className="logo-bullet" />
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("benefitsText5")}
              </p>
            </div>
            <div className="flex-container">
              <div className="thin-line">
                <div className="thick-line" />
              </div>
              <div className="logo-bullet" />
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("benefitsText6")}
              </p>
            </div>
            <div className="flex-container">
              <div className="thin-line">
                <div className="thick-line" />
              </div>
              <div className="logo-bullet" />
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("benefitsText7")}
              </p>
            </div>
            <div className="flex-container">
              <div className="thin-line">
                <div className="thick-line" />
              </div>
              <div className="logo-bullet" />
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("benefitsText8")}
              </p>
            </div>
            <div className="flex-container last">
              <div className="thin-line">
                <div className="thick-line" />
              </div>
              <div className="logo-bullet" />
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("benefitsText9")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="right-container" />
    </div>
  );
}

export default BenefitsComponent;
