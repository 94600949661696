import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { setLocale } from "../../translations/localization";
import { setLanguage, setLanguageToLocalStorage } from "../../actions";
import { history } from "../../config/stores";
import "./styles.scss";

function NewHeader({ language, setLanguage, setLanguageToLocalStorage }) {
  const [opened, setOpened] = useState(false);
  useEffect(() => {
    if (!window.localStorage.code) {
      setLanguage("en");
      setLanguageToLocalStorage("en");
      setLocale("en");
    }
  }, [setLanguage, setLanguageToLocalStorage]);

  useEffect(() => {
    if (opened) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  }, [opened]);

  const location = useLocation();

  return (
    <div
      className={`mobile-header-container ${
        location.pathname.includes("PrivacyPolicy") && "display-none"
      }`}
    >
      {!opened ? (
        <div className="unopened-header">
          <div className="header-menu" onClick={() => setOpened(true)} />
          <div
            className="header-logo"
            onClick={() => history.push(`/${language}/`)}
          />
          <div className="dummy" />
        </div>
      ) : (
        <div className="opened-header">
          <div className="upper-container">
            <div className="exit-btn-container">
              <div className="exit-btn" onClick={() => setOpened(false)} />
            </div>
            <div className="main-menu-content">
              <div
                className="flex-container"
                onClick={() => {
                  history.push(`/${language}/WorkThisWay`);
                  setOpened(false);
                }}
              >
                <p id="bold">WORK</p>
                <p id="light">THIS WAY</p>
              </div>
              <div
                className="flex-container"
                onClick={() => {
                  history.push(`/${language}/Events`);
                  setOpened(false);
                }}
              >
                <p id="bold">MEET</p>
                <p id="light">THIS WAY</p>
              </div>
              <p
                onClick={() => {
                  history.push(`/${language}/Events`);
                  setOpened(false);
                }}
              >
                EVENTS
              </p>
              <p
                onClick={() => {
                  history.push(`/${language}/Meetings`);
                  setOpened(false);
                }}
              >
                MEETINGS
              </p>
              <div
                className="flex-container"
                onClick={() => {
                  history.push(`/${language}/WhyThisWay`);
                  setOpened(false);
                }}
              >
                <p id="bold">WHY</p>
                <p id="light">THIS WAY</p>
              </div>
              <p
                onClick={() => {
                  history.push(`/${language}/WhyThisWay`);
                  setOpened(false);
                }}
              >
                BENEFITS
              </p>
              <p
                onClick={() => {
                  history.push(`/${language}/GalleryTour`);
                  setOpened(false);
                }}
              >
                GALLERY & VIRTUAL TOUR
              </p>
              <div
                className="flex-container"
                onClick={() => {
                  history.push(`/${language}/AboutThisWay`);
                  setOpened(false);
                }}
              >
                <p id="bold">ABOUT</p>
                <p id="light">THIS WAY</p>
              </div>
              <p
                onClick={() => {
                  history.push(`/${language}/AboutThisWay`);
                  setOpened(false);
                }}
              >
                THIS IS US
              </p>
              <p
                onClick={() => {
                  history.push(`/${language}/Stories`);
                  setOpened(false);
                }}
              >
                STORIES
              </p>
              <p
                onClick={() => {
                  history.push(`/${language}/Locations`);
                  setOpened(false);
                }}
              >
                LOCATIONS
              </p>
            </div>
            {/* <div className="flex-language">
              <div
                className={`language-element ${
                  window.localStorage.getItem("code") === "en" ? "selected" : ""
                }`}
                onClick={() => {
                  setLanguage("en");
                  setLanguageToLocalStorage("en");
                  setLocale("en");
                  const path =
                    window.location.pathname.split("/").slice(2).join("/") ||
                    "";
                  if (path) {
                    history.push(`/en/${path}`);
                  } else {
                    history.push(`/en`);
                  }
                }}
              >
                EN
              </div>
              <div
                className={`language-element ${
                  window.localStorage.getItem("code") === "bg" ? "selected" : ""
                }`}
                onClick={() => {
                  setLanguage("bg");
                  setLanguageToLocalStorage("bg");
                  setLocale("bg");
                  const path =
                    window.location.pathname.split("/").slice(2).join("/") ||
                    "";
                  if (path) {
                    history.push(`/bg/${path}`);
                  } else {
                    history.push(`/bg`);
                  }
                }}
              >
                BG
              </div>
            </div> */}
          </div>
          <div className="lower-container" />
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  setLanguage: (language) => dispatch(setLanguage(language)),
  setLanguageToLocalStorage: (payload) =>
    dispatch(setLanguageToLocalStorage(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewHeader);
