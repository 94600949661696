import React, { useState } from "react";
import { TitleComponent, ViewMoreComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

const mobilePictures = [
  "/logos/truevo-logo.svg",
  "/logos/alcon-logo.jpg",
  "/logos/fidweb-black.svg",
  "/logos/httpool-logo-white.svg",
  "/logos/smarkethink-logo.png",
  "/logos/able-logo.png",
  "/logos/ingram-logo.png",
  "/logos/rm-logo.png",
  "/logos/m3-logo.png",
];

function TestimonialsComponent({ onScroll }) {
  const [index, setIndex] = useState(0);
  const testimonials = [
    {
      title: "Truevo",
      text: `"Our team members enjoy working in This Way's beautiful office space. We're grateful for their top notch service which keeps everyone efficient and productive."`,
      person:
        "- Diana Malatsilajeva, Head of Global Expansion & Client Relations",
    },
    {
      title: "Alcon",
      text: `“Alcon Bulgaria started to work at This Way office area 1 year ago. ThisWay is an elegant, modern co-working space with inspiring décor and 24-hour access, which makes it a perfect and convenient work environment. At this amazing area you can work everywhere – office, coffee corner, meeting rooms, boots, kitchen, cozy sofa. ThisWay provides all the needed conditions for a smooth working process - from free coffee to parking, mail handling, and live reception throughout the day, from modern conference rooms to lounge areas, spacious kitchen and dining area. The pricing is all inclusive. This Way team and reception services are at very high quality. Daily we receive full & friendly support. The office space is very clean and sanitized, according to the latest requirements. This Way full service is really first class.”`,
      person: "- Alcon Bulgaria Team",
    },
    {
      title: "Fidweb",
      text: `"From the moment you step in, you can feel that ThisWay is not just another working space. Every single detail here vibrates with the purpose to boost your creativity, and invites you to deliver nothing more than your highest performance. Working here is like being part of an extraordinary community of talented professionals on a mission to change the world through constant innovation. Holistic, motivational and diverse - ThisWay is a must-experience destination for any modern company looking to go way beyond the standard concept of working environment."`,
    },
    {
      title: "Httpool",
      text: `“Best event space I’ve ever been to, cozy and modern interior, wonderful & professional team. I highly recommend THIS WAY for your event.”`,
      person: "- Boriana Jeleva",
    },
    {
      title: "Smarkethink",
      text: `“It was a great experience working with THIS WAY team on such big event considering all the customer requirements and wishes. They were very responsive, adequate and friendly. Thank you”`,
      person: "- Gergana Bratanova",
    },
    {
      title: "Association of the Bulgarian Leaders and Entrepreneurs (ABLE)",
      text: `“Spacious and beautifully designed, the event hall in This Way was the perfect spot for one of our organization’s special events. With everything being high-quality without feeling overdone, a few elements that truly set this space apart are the tall ceilings, the stylish yet comfortable furniture, and the breathtaking vertical garden. Customer service was beyond expectations! Customer service was beyond expectations! So glad to have found you guys!”`,
      person: "- Kremena Dervenkova",
    },
    {
      title: "Ingram Micro",
      text: `“I am very pleased with all assistance provided by THIS WAY associates. They are flexible, responsive, positive & very kind. The location is modern & cozy, all participants were really amazed by the venue & on - site support.” - Lora Andreeva`,
      person: "- Lora Andreeva",
      text2: `“The staff was so helpful all the time! Also we all loved the amazing industrial design of the event space!” - Peter Petrov`,
      person2: "- Peter Petrov",
    },
    {
      title: "RM Programs",
      text: `“This Way is an amazing and special place with very good customer service.”`,
    },
    {
      title: "M3 Communications",
      text: `“You know it was awesome. I really love this place.”`,
      person: "- George Ivanov",
    },
  ];
  return (
    <div className="testimonials-container">
      <div className="left-container">
        <div className="text-container">
          <TitleComponent title1={translate("benefitsTestimonialTitle")} />
          <h3>{testimonials[index].title}</h3>
          <p>{testimonials[index].text}</p>
          {testimonials[index].person && <p>{testimonials[index].person}</p>}
          {testimonials[index].text2 && <p>{testimonials[index].text2}</p>}
          {testimonials[index].person2 && <p>{testimonials[index].person2}</p>}
          {/* <div className="btns-container">
            <div
              className="button-one"
              onClick={() => {
                if (index > 0) {
                  setIndex(index - 1);
                }
              }}
            />
            <div
              className="button-two"
              onClick={() => {
                if (index < testimonials.length - 1) {
                  setIndex(index + 1);
                }
              }}
            />
          </div> */}
          <ViewMoreComponent
            right
            textBold={translate("buttonText5")}
            textLight={translate("buttonText6")}
            onClick={() => onScroll()}
          />
          <ViewMoreComponent
            right
            textBold={translate("buttonText3")}
            textLight={translate("buttonText4")}
            onClick={() =>
              window.open("https://mpembed.com/show/?m=UsxETWZnxaW")
            }
          />
        </div>
        {window.innerWidth < 900 && (
          <div className="flex-container space-between">
            <div
              className="button left"
              onClick={() => {
                if (index > 0) {
                  setIndex((prev) => prev - 1);
                }
              }}
            />
            <div
              className="button right"
              onClick={() => {
                if (index < mobilePictures.length - 1) {
                  setIndex((prev) => prev + 1);
                }
              }}
            />
          </div>
        )}
      </div>
      <div
        className="right-container"
        style={
          window.innerWidth < 900
            ? { backgroundImage: `url(${mobilePictures[index]})` }
            : {}
        }
      >
        {window.innerWidth > 900 && (
          <>
            <div className="element white truevo" onClick={() => setIndex(0)}>
              <div className={`inner-border ${index === 0 && "selected"}`} />
            </div>
            <div
              className="element black smarkethink"
              onClick={() => setIndex(4)}
            >
              <div className={`inner-border ${index === 4 && "selected"}`} />
            </div>
            <div className="element white alcon" onClick={() => setIndex(1)}>
              <div className={`inner-border ${index === 1 && "selected"}`} />
            </div>
            <div className="element black ingram" onClick={() => setIndex(6)}>
              <div className={`inner-border ${index === 6 && "selected"}`} />
            </div>
            <div className="element white rm" onClick={() => setIndex(7)}>
              <div className={`inner-border ${index === 7 && "selected"}`} />
            </div>
            <div className="element black fidweb" onClick={() => setIndex(2)}>
              <div className={`inner-border ${index === 2 && "selected"}`} />
            </div>
            <div className="element white httpool" onClick={() => setIndex(3)}>
              <div className={`inner-border ${index === 3 && "selected"}`} />
            </div>
            <div className="element black able" onClick={() => setIndex(5)}>
              <div className={`inner-border ${index === 5 && "selected"}`} />
            </div>
            <div className="element white m" onClick={() => setIndex(8)}>
              <div className={`inner-border ${index === 8 && "selected"}`} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TestimonialsComponent;
