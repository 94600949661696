import React from "react";
import { TitleComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function BenefitsText() {
  const chosenLanguage = localStorage.getItem("code");
  return (
    <div className="benefits-text-container">
      <div className="left-container" />
      <div className="right-container">
        <div className="text-container">
          <TitleComponent
            bulgarianSpecial
            title1={translate("benefits1")}
            title2={translate("benefits2")}
          />
          {window.innerWidth < 900 && <div className="mobile-picture" />}
          <h3 style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}>
            {translate("benefitsSubTitle1")}
          </h3>
          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {translate("benefitsSubText1")}
          </p>
          <h3 style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}>
            {translate("benefitsSubTitle2")}
          </h3>
          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {translate("benefitsSubtText2")}
          </p>
          <h3 style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}>
            {translate("benefitsSubTitle3")}
          </h3>
          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {translate("benefitsSubText3")}
          </p>
          <h3 style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}>
            {translate("benefitsSubTitle4")}
          </h3>
          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {translate("benefitsSubText4")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BenefitsText;
