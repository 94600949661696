import React from "react";
import { connect } from "react-redux";
import { history } from "../../config/stores";
import "./styles.scss";

function OpenedStory({ currentStory }) {
  return (
    <div className="story-container">
      <div className="left-container">
        <div className="left-inner-container">
          <div className="back-btn-container" onClick={() => history.goBack()}>
            <div className="left-arrow" />
            <p>Go Back</p>
          </div>
          <h1>{currentStory.title}</h1>
          <p id="italic">{currentStory.author}</p>
          <div className="scroll-container">
            {currentStory.text.map((element) => (
              <p>{element}</p>
            ))}
          </div>
        </div>
      </div>
      <div
        className="right-container"
        style={{ backgroundImage: `url(${currentStory.image})` }}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  currentStory: state.stories.currentStory,
});

export default connect(mapStateToProps, null)(OpenedStory);
