import React, { useState, useEffect } from "react";
import "./styles.scss";

const details = [
  "/galleryModalPhotos/Details/-3.jpg",
  "/galleryModalPhotos/Details/-2.JPG",
  "/galleryModalPhotos/Details/-1.JPG",
  "/galleryModalPhotos/Details/0.JPG",
  "/galleryModalPhotos/Details/1.JPG",
  "/galleryModalPhotos/Details/2.JPG",
  "/galleryModalPhotos/Details/3.JPG",
  "/galleryModalPhotos/Details/4.JPG",
  "/galleryModalPhotos/Details/5.JPG",
  "/galleryModalPhotos/Details/6.jpg",
  "/galleryModalPhotos/Details/7.JPG",
  "/galleryModalPhotos/Details/8.JPG",
  "/galleryModalPhotos/Details/9.JPG",
  "/galleryModalPhotos/Details/10.JPG",
  "/galleryModalPhotos/Details/11.jpg",
  "/galleryModalPhotos/Details/12.jpg",
  "/galleryModalPhotos/Details/13.jpg",
  "/galleryModalPhotos/Details/14.jpg",
  "/galleryModalPhotos/Details/15.JPG",
  "/galleryModalPhotos/Details/16.JPG",
  "/galleryModalPhotos/Details/17.jpeg",
  "/galleryModalPhotos/Details/18.jpg",
];

const events = [
  "/galleryModalPhotos/Events/-2.jpg",
  "/galleryModalPhotos/Events/-1.jpg",
  "/galleryModalPhotos/Events/0.jpeg",
  "/galleryModalPhotos/Events/1.JPG",
  "/galleryModalPhotos/Events/2.JPG",
  "/galleryModalPhotos/Events/3.JPG",
  "/galleryModalPhotos/Events/4.jpg",
  "/galleryModalPhotos/Events/5.jpg",
  "/galleryModalPhotos/Events/6.jpg",
  "/galleryModalPhotos/Events/7.jpg",
  "/galleryModalPhotos/Events/8.jpeg",
];

const MeetingRooms = [
  "/galleryModalPhotos/MeetingRooms/-3.jpg",
  "/galleryModalPhotos/MeetingRooms/-2.jpg",
  "/galleryModalPhotos/MeetingRooms/-1.jpg",
  "/galleryModalPhotos/MeetingRooms/0.jpg",
  "/galleryModalPhotos/MeetingRooms/1.jpg",
  "/galleryModalPhotos/MeetingRooms/2.JPG",
  "/galleryModalPhotos/MeetingRooms/3.jpg",
  "/galleryModalPhotos/MeetingRooms/4.jpg",
  "/galleryModalPhotos/MeetingRooms/5.jpg",
  "/galleryModalPhotos/MeetingRooms/6.jpg",
  "/galleryModalPhotos/MeetingRooms/7.jpg",
  "/galleryModalPhotos/MeetingRooms/8.jpg",
  "/galleryModalPhotos/MeetingRooms/9.JPG",
];

const offices = [
  "/galleryModalPhotos/Offices/-3.jpg",
  "/galleryModalPhotos/Offices/-2.jpg",
  "/galleryModalPhotos/Offices/-1.jpg",
  "/galleryModalPhotos/Offices/0.jpg",
  "/galleryModalPhotos/Offices/1.JPG",
  "/galleryModalPhotos/Offices/2.JPG",
  "/galleryModalPhotos/Offices/3.jpg",
  "/galleryModalPhotos/Offices/4.jpg",
  "/galleryModalPhotos/Offices/5.jpg",
  "/galleryModalPhotos/Offices/6.jpg",
  "/galleryModalPhotos/Offices/7.jpg",
  "/galleryModalPhotos/Offices/8.JPG",
];

const sharedSpace = [
  "/galleryModalPhotos/SharedSpace/-3.jpg",
  "/galleryModalPhotos/SharedSpace/-2.jpg",
  "/galleryModalPhotos/SharedSpace/-1.jpg",
  "/galleryModalPhotos/SharedSpace/0.jpg",
  "/galleryModalPhotos/SharedSpace/1.JPG",
  "/galleryModalPhotos/SharedSpace/2.JPG",
  "/galleryModalPhotos/SharedSpace/3.JPG",
  "/galleryModalPhotos/SharedSpace/4.JPG",
  "/galleryModalPhotos/SharedSpace/5.JPG",
  "/galleryModalPhotos/SharedSpace/6.JPG",
  "/galleryModalPhotos/SharedSpace/7.JPG",
  "/galleryModalPhotos/SharedSpace/8.JPG",
  "/galleryModalPhotos/SharedSpace/9.JPG",
  "/galleryModalPhotos/SharedSpace/10.JPG",
  "/galleryModalPhotos/SharedSpace/11.JPG",
  "/galleryModalPhotos/SharedSpace/12.jpg",
  "/galleryModalPhotos/SharedSpace/13.jpg",
  "/galleryModalPhotos/SharedSpace/14.jpg",
  "/galleryModalPhotos/SharedSpace/15.jpg",
  "/galleryModalPhotos/SharedSpace/16.jpg",
  "/galleryModalPhotos/SharedSpace/17.jpg",
  "/galleryModalPhotos/SharedSpace/18.jpg",
  "/galleryModalPhotos/SharedSpace/19.jpg",
  "/galleryModalPhotos/SharedSpace/20.JPG",
  "/galleryModalPhotos/SharedSpace/21.JPG",
  "/galleryModalPhotos/SharedSpace/22.jpg",
  "/galleryModalPhotos/SharedSpace/23.JPG",
];

function GalleryModal({ onClose, initialImage, initialCategory }) {
  const [category, setCategory] = useState("Meeting Rooms");
  const [categoryImages, setCategoryImages] = useState(MeetingRooms);
  const [selectedImage, setSelectedImage] = useState(MeetingRooms[0]);

  useEffect(() => {
    if (category === "Opened Space") {
      setCategoryImages(sharedSpace);
      setSelectedImage(sharedSpace[0]);
    } else if (category === "Meeting Rooms") {
      setCategoryImages(MeetingRooms);
      setSelectedImage(MeetingRooms[0]);
    } else if (category === "Offices") {
      setCategoryImages(offices);
      setSelectedImage(offices[0]);
    } else if (category === "Events") {
      setCategoryImages(events);
      setSelectedImage(events[0]);
    } else if (category === "Details") {
      setCategoryImages(details);
      setSelectedImage(details[0]);
    }
  }, [category]);

  useEffect(() => {
    if (initialImage && initialCategory) {
      setCategoryImages(initialCategory);
      const imageIndex = initialCategory.findIndex((el) => el === initialImage);
      setSelectedImage(initialCategory[imageIndex]);
      const dataString = initialCategory[0].split("/");
      if (dataString[2] === "SharedSpace") {
        setCategory("Opened Space");
      } else if (dataString[2] === "MeetingRooms") {
        setCategory("Meeting Rooms");
      } else if (dataString[2] === "Offices") {
        setCategory("Offices");
      } else if (dataString[2] === "Details") {
        setCategory("Details");
      } else if (dataString[2] === "Events") {
        setCategory("Events");
      }
    }
  }, [initialImage]);

  return (
    <div className="gallery-modal-container">
      <div className="exit-btn-wrapper" onClick={onClose}>
        <div className="exit-btn" />
      </div>
      <div className="gallery-modal-outer">
        <div className="gallery-modal-inner">
          <div className="tab-buttons-container">
            <div
              className={`tab-element ${
                category === "Opened Space" && "selected"
              }`}
              onClick={() => {
                setCategory("Opened Space");
              }}
            >
              Opened Space
            </div>
            <div
              className={`tab-element ${
                category === "Meeting Rooms" && "selected"
              }`}
              onClick={() => {
                setCategory("Meeting Rooms");
              }}
            >
              Meeting Rooms
            </div>
            <div
              className={`tab-element ${category === "Offices" && "selected"}`}
              onClick={() => {
                setCategory("Offices");
              }}
            >
              Offices
            </div>
            <div
              className={`tab-element ${category === "Events" && "selected"}`}
              onClick={() => {
                setCategory("Events");
              }}
            >
              Events
            </div>
            <div
              className={`tab-element ${category === "Details" && "selected"}`}
              onClick={() => {
                setCategory("Details");
              }}
            >
              Details
            </div>
          </div>
          <div
            className="selected-image"
            style={{ backgroundImage: `url(${selectedImage})` }}
          />
        </div>
      </div>
      <div className="image-thumbnails-container">
        <div className="buttons-container">
          <div
            className="left-button"
            onClick={() => {
              const index = categoryImages.findIndex(
                (el) => el === selectedImage
              );
              if (index > 0) {
                setSelectedImage(categoryImages[index - 1]);
              } else {
                setSelectedImage(categoryImages[categoryImages.length - 1]);
              }
            }}
          />
          <div
            className="right-button"
            onClick={() => {
              const index = categoryImages.findIndex(
                (el) => el === selectedImage
              );
              if (index < categoryImages.length - 1) {
                setSelectedImage(categoryImages[index + 1]);
              } else {
                setSelectedImage(categoryImages[0]);
              }
            }}
          />
        </div>
        {categoryImages.map((image) => {
          return (
            <div
              className="category-image"
              style={{ backgroundImage: `url(${image})` }}
              onClick={() => setSelectedImage(image)}
            />
          );
        })}
      </div>
    </div>
  );
}

export default GalleryModal;
