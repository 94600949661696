import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  ViewMoreComponent,
  TitleComponent,
  HLSVideoPlayer,
} from "../../components";
import { useInView } from "react-intersection-observer";
import { history } from "../../config/stores";
import "./styles.scss";

function MainTextComponent({
  right,
  content,
  switched,
  noFlex,
  video,
  image,
  animation,
  id,
  language,
  specialVideo,
  onScroll,
  noMarginParagraph,
}) {
  const [ref, inView] = useInView();
  const [domElement, setDomElement] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const chosenLanguage = localStorage.getItem("code");
  useEffect(() => {
    let element = document.getElementById(id);
    setDomElement(element);
  }, [id]);
  useEffect(() => {
    if (inView && animation && domElement) {
      setTrigger(true);
      domElement.className = "inner-overlay in-view";
      setTimeout(() => {
        let textElements = document.getElementsByClassName(
          `text-element-container ${id} 0`
        );
        let textElements2 = document.getElementsByClassName(
          `text-element-container ${id} 1`
        );
        if (textElements.length) {
          textElements[0].className = "text-element-container triggered";
          if (textElements2.length) {
            textElements2[0].className = "text-element-container triggered";
          }
        }
      }, 4000);
    }
  }, [inView, domElement, animation, id]);

  return (
    <div
      ref={ref}
      className="main-text-container"
      style={{ backgroundImage: `url(${image})` }}
    >
      {video && <HLSVideoPlayer videoURL={video} specialVideo={specialVideo} />}
      <div
        id={id}
        className="inner-overlay"
        style={
          right
            ? {
                right: 0,
                backgroundColor: switched
                  ? window.innerWidth < 900
                    ? "black"
                    : "rgba(0, 0, 0, 0.85)"
                  : "",
                color: switched ? "white" : "",
              }
            : {
                left: 0,
                backgroundColor: switched
                  ? "rgba(255, 255, 255, 0.85)"
                  : window.innerWidth < 900
                  ? "black"
                  : "rgba(0, 0, 0, 0.85)",
                color: switched ? "black" : "white",
              }
        }
      >
        <div className="text-container">
          <TitleComponent
            animation
            id={id}
            trigger={trigger}
            title1={content.title1}
            title2={content.title2}
          />
          {content.text.map((element, index) => {
            return (
              <div
                className={`text-element-container ${id} ${index}`}
                style={(noMarginParagraph && { marginBottom: "10px" }) || {}}
              >
                <h3
                  id="bolder"
                  style={
                    (chosenLanguage === "bg" && { fontFamily: "bold" }) || {}
                  }
                >
                  {element.subtitle}
                </h3>
                <p
                  style={
                    (chosenLanguage === "bg" && {
                      fontFamily: "thin",
                      lineHeight: 1.1,
                      marginBottom: "10px",
                    }) ||
                    (noMarginParagraph && { marginBottom: "10px" })
                  }
                >
                  {element.text}
                </p>
                <p
                  style={
                    (chosenLanguage === "bg" && {
                      fontFamily: "thin",
                      lineHeight: 1.1,
                      marginBottom: "10px",
                    }) ||
                    (noMarginParagraph && { marginBottom: "10px" })
                  }
                >
                  {element.text2 && element.text2}
                </p>
                {element.additionalText &&
                  element.additionalText.map((text) => {
                    return <p style={{ marginBottom: "10px" }}>{text}</p>;
                  })}
                <div
                  className="flex-container"
                  style={noFlex ? { display: "block" } : {}}
                >
                  {element.additionalInfo.map((element) => (
                    <ViewMoreComponent
                      switched={switched}
                      right={right}
                      textBold={element.textBold}
                      textLight={element.textLight}
                      noFlex={noFlex}
                      onClick={() => {
                        if (element.link) {
                          window.open(element.link);
                        } else if (element.page) {
                          history.push(`/${language}/${element.page}`);
                          window.scrollTo({ top: 0, behavior: "smooth" });
                        } else if (
                          element.textBold === "CONTACT" ||
                          element.textBold === "СВЪРЖЕТЕ СЕ"
                        ) {
                          onScroll();
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {image && window.innerWidth < 900 ? (
        <div
          className="main-text-image"
          style={{ backgroundImage: `url(${image})` }}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.selectedLanguage,
});

export default connect(mapStateToProps, null)(MainTextComponent);
