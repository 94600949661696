import React, { useState, useEffect } from "react";
import { GoogleMaps, TitleComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function LocationsSection({ notHome }) {
  const [text1, setText1] = useState(translate("homeLocationAddress2"));
  const [text12, setText12] = useState(translate("homeLocationAddress22"));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [text2, setText2] = useState(translate("homeLocationText2"));
  const [email, setEmail] = useState("sofia@sterlingoffice.com");
  const [phone, setPhone] = useState("+359 (0)2 926 4333");
  const [location, setLocation] = useState(
    "Sterling Serviced Offices Serdika Center"
  );
  const [mapsView, setMapsView] = useState("gallery");
  const [currentImage, setCurrentImage] = useState("/imgs/serdika1.jpg");
  const chosenLanguage = localStorage.getItem("code");

  useEffect(() => {
    if (window.innerWidth < 900) {
      setLocation("This Way");
      setText1(translate("homeLocationText"));
      setText12("");
      setText2(translate("homeLocationText2"));
      setEmail("info@thisway.com");
      setPhone("+359 2 9264264");
      setCurrentImage("/imgs/Office_A1.jpg");
    }
  }, []);
  return (
    <div
      className="locations-container"
      style={
        window.innerWidth < 900
          ? {}
          : {
              backgroundImage: `url(${
                mapsView === "maps" ? "" : currentImage
              })`,
            }
      }
    >
      <div className="location-info-container">
        <div className="location-info-inner-container">
          <TitleComponent
            noMargin
            title1={notHome ? translate("locations") : translate("homeTitle2")}
            title2={!notHome && "THIS WAY"}
          />
          {window.innerWidth > 900 && (
            <>
              <div className="icons-flex-container">
                <div
                  className="main-icon"
                  style={{
                    backgroundImage: `url("/icons/iconog.svg")`,
                  }}
                />
                <h2>This Way</h2>
                <div
                  className="icon-flex-column-container"
                  onClick={() => {
                    setLocation("This Way");
                    setCurrentImage("/imgs/Office_A1.jpg");
                    setMapsView("maps");
                  }}
                >
                  <div
                    className="selected-icon"
                    style={{
                      opacity:
                        location === "This Way" && mapsView === "maps" ? 1 : 0,
                    }}
                  />
                  <div className="location-icon" />
                </div>
                <div
                  className="icon-flex-column-container"
                  onClick={() => {
                    setCurrentImage("/imgs/Office_A1.jpg");
                    setLocation("This Way");
                    setMapsView("gallery");
                  }}
                >
                  <div
                    className="selected-icon"
                    style={{
                      opacity:
                        currentImage === "/imgs/Office_A1.jpg" &&
                        mapsView === "gallery"
                          ? 1
                          : 0,
                    }}
                  />
                  <div className="pictures-icon" />
                </div>
              </div>
              <p id="margin">This Way:</p>
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("locationOfficeA")}
              </p>
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("locationOfficeA1")}
              </p>
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("locationOfficeA2")}
              </p>
              <div className="flex-container margin-bottom">
                <div className="flex-container margin-right">
                  <p>E:</p>
                  <span>info@thisway.com</span>
                </div>
                <div className="flex-container">
                  <p>T:</p>
                  <span>+359 2 9264264</span>
                </div>
              </div>
              <h3
                style={
                  (chosenLanguage === "bg" && { fontFamily: "regular" }) || {}
                }
              >
                {translate("moreLocations")}
              </h3>
            </>
          )}

          {window.innerWidth < 900 ? (
            <div className="dropdown-container">
              <div
                className="unopened-container"
                onClick={() => setDropdownOpen(!dropdownOpen)}
              >
                <div className="dummy" />
                <div className="selected-region">
                  {location === "Sterling Serviced Offices Serdika Center"
                    ? "Sterling Serdika Center"
                    : location === "Sterling Serviced Offices Astoria"
                    ? "Sterling Astoria"
                    : location}
                </div>
                <div className="arrow-btn" />
              </div>
              {dropdownOpen ? (
                <div className="opened-container">
                  <div
                    className={`location-element ${
                      location === "This Way" ? "selected" : ""
                    }`}
                    onClick={() => {
                      setText1(translate("homeLocationText"));
                      setText12("");
                      setText2(translate("homeLocationText2"));
                      setEmail("info@thisway.com");
                      setPhone("+359 2 9264264");
                      setCurrentImage("/imgs/Office_A1.jpg");
                      setLocation("This Way");
                      setDropdownOpen(false);
                    }}
                  >
                    This Way
                  </div>
                  {/* <div
                    className={`location-element ${
                      location === "Sterling Serviced Offices Saborna St."
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      setText1(translate("homeLocationAddress"));
                      setText12(translate("homeLocationAddress12"));
                      setText2(translate("homeLocationText2"));
                      setEmail("sofia@sterlingoffice.com");
                      setPhone("+359 (0)2 926 4111");
                      setCurrentImage("/imgs/saborna-min.jpg");
                      setLocation("Sterling Serviced Offices Saborna St.");
                      setDropdownOpen(false);
                    }}
                  >
                    Sterling Saborna St.
                  </div> */}
                  <div
                    className={`location-element ${
                      location === "Sterling Serviced Offices Serdika Center"
                        ? "selected"
                        : ""
                    }`}
                    onClick={() => {
                      setText1(translate("homeLocationAddress2"));
                      setText12(translate("homeLocationAddress22"));
                      setText2(translate("homeLocationText2"));
                      setEmail("sofia@sterlingoffice.com");
                      setPhone("+359 (0)2 926 4333");
                      setCurrentImage("/imgs/serdika1.jpg");
                      setLocation("Sterling Serviced Offices Serdika Center");
                      setDropdownOpen(false);
                    }}
                  >
                    Sterling Serdika Center
                  </div>
                  {notHome && (
                    <div
                      className={`location-element ${
                        location === "Sterling Serviced Offices Astoria"
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => {
                        setText1("The Astoria, Tower A,");
                        setText12("127 Büyükdere Cad. 34394,");
                        setText2("Istanbul, Turkey");
                        setEmail("istanbul@sterlingoffice.com");
                        setPhone("+90 (0)212 340 7600");
                        setCurrentImage("/imgs/istanbul.jpg");
                        setLocation("Sterling Serviced Offices Astoria");
                        setDropdownOpen(false);
                      }}
                    >
                      Sterling Astoria
                    </div>
                  )}
                </div>
              ) : null}
            </div>
          ) : (
            <div className="locations-flex-container">
              {/* <div
                className={`location ${
                  location === "This Way" ? "selected" : ""
                }`}
                onClick={() => {
                  setText1(translate("homeLocationText"));
                  setText2(translate("homeLocationText2"));
                  setEmail("info@thisway.com");
                  setPhone("+359 2 9264264");
                  setCurrentImage("/imgs/Coworking-Facilities.jpg");
                  setLocation("This Way");
                }}
              >
                This Way
              </div> */}
              {/* <div
                className={`location ${
                  location === "Sterling Serviced Offices Saborna St."
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  setText1(translate("homeLocationAddress"));
                  setText12(translate("homeLocationAddress12"));
                  setText2(translate("homeLocationText2"));
                  setEmail("sofia@sterlingoffice.com");
                  setPhone("+359 (0)2 926 4111");
                  setCurrentImage("/imgs/saborna-min.jpg");
                  setLocation("Sterling Serviced Offices Saborna St.");
                }}
              >
                Sterling Saborna St.
              </div> */}
              <div
                className={`location ${
                  location === "Sterling Serviced Offices Serdika Center"
                    ? "selected"
                    : ""
                }`}
                onClick={() => {
                  setText1(translate("homeLocationAddress2"));
                  setText12(translate("homeLocationAddress22"));
                  setText2(translate("homeLocationText2"));
                  setEmail("sofia@sterlingoffice.com");
                  setPhone("+359 (0)2 926 4333");
                  setCurrentImage("/imgs/serdika1.jpg");
                  setLocation("Sterling Serviced Offices Serdika Center");
                }}
              >
                Sterling Serdika Center
              </div>
              {notHome && (
                <div
                  className={`location ${
                    location === "Sterling Serviced Offices Astoria"
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => {
                    setText1("The Astoria, Tower A,");
                    setText12("127 Büyükdere Cad. 34394,");
                    setText2("Istanbul, Turkey");
                    setEmail("istanbul@sterlingoffice.com");
                    setPhone("+90 (0)212 340 7600");
                    setCurrentImage("/imgs/istanbul.jpg");
                    setLocation("Sterling Serviced Offices Astoria");
                  }}
                >
                  Sterling Astoria
                </div>
              )}
            </div>
          )}

          <div className="icons-flex-container">
            <div
              className="main-icon"
              style={{
                backgroundImage:
                  location === "This Way"
                    ? `url("/icons/thisway-logo.svg")`
                    : `url("/icons/sterling-logo.svg")`,
              }}
            />
            <h2>{location}</h2>
            <div
              className="icon-flex-column-container"
              onClick={() => {
                setMapsView("maps");
              }}
            >
              <div
                className="selected-icon"
                style={{
                  opacity: mapsView === "maps" ? 1 : 0,
                }}
              />
              <div className="location-icon" />
            </div>
            <div
              className="icon-flex-column-container"
              onClick={() => {
                setMapsView("gallery");
              }}
            >
              <div
                className="selected-icon"
                style={{
                  opacity: mapsView === "gallery" ? 1 : 0,
                }}
              />
              <div className="pictures-icon" />
            </div>
          </div>
          <p
            id="margin-top"
            style={(chosenLanguage === "bg" && { fontFamily: "regular" }) || {}}
          >
            {text1}
          </p>
          <p
            style={(chosenLanguage === "bg" && { fontFamily: "regular" }) || {}}
          >
            {text12}
          </p>
          <p
            style={(chosenLanguage === "bg" && { fontFamily: "regular" }) || {}}
          >
            {text2}
          </p>
          <div className="flex-container">
            <p id="margin-right">E: {email}</p>
            <p>T: {phone}</p>
          </div>
          {location !== "This Way" && (
            <a href="https://sterlingoffice.com/en">
              W: www.sterlingoffice.com
            </a>
          )}
        </div>
      </div>
      <div className="maps-container">
        {mapsView === "gallery" ? (
          window.innerWidth < 900 ? (
            <div
              className="location-image"
              style={{ backgroundImage: `url(${currentImage})` }}
            />
          ) : null
        ) : (
          <GoogleMaps
            address={location}
            location={
              location === "This Way"
                ? { lat: 42.66267591155627, lng: 23.380816440601293 }
                : location === "Sterling Serviced Offices Saborna St."
                ? { lat: 42.696472922955834, lng: 23.323773003089357 }
                : location === "Sterling Serviced Offices Serdika Center"
                ? { lat: 42.692053866726255, lng: 23.354412325258266 }
                : { lat: 41.06886773069345, lng: 29.00577735605992 }
            }
          />
        )}
      </div>
    </div>
  );
}

export default LocationsSection;
