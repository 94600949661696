import { isArray } from "lodash";
import React, { useState, useEffect } from "react";
import { ViewMoreComponent, TitleComponent } from "../../components";
import "./styles.scss";

function TextSelectionComponent({
  content,
  right,
  images,
  short,
  onScroll,
  faster,
  small,
  mobileSmaller,
}) {
  const [selected, setSelected] = useState(0);
  const chosenLanguage = localStorage.getItem("code");
  useEffect(() => {
    const next = (selected + 1) % images.length;
    const time = faster ? 3000 : 5000;
    const id = setTimeout(() => setSelected(next), time);
    return () => clearTimeout(id);
  }, [selected, images, faster]);
  return window.innerWidth < 900 ? (
    <div className="text-select-mobile-container">
      <div
        className={`black-container ${right ? "" : "reversed"} ${
          mobileSmaller && "mobile-smaller"
        }`}
      >
        <TitleComponent title1={content.title1} title2={content.title2} />
        <h3>Flexible Workspace Options</h3>
        <p>
          Tailored office solutions across 275 workstations in open plan and
          team office formats
        </p>
      </div>
      {images[0] ? (
        <div
          className="image"
          style={{ backgroundImage: `url(${images[0]})` }}
        />
      ) : null}

      {content.innerContent.slice(1).map((element, index) => {
        return (
          <>
            <div
              className={`black-container ${right ? "" : "reversed"} ${
                mobileSmaller && "mobile-smaller"
              }`}
            >
              <h3
                style={
                  (chosenLanguage === "bg" && { fontFamily: "bold" }) || {}
                }
              >
                {element.subtitle}
              </h3>
              <p
                style={
                  (chosenLanguage === "bg" && { fontFamily: "thin" }) || {}
                }
              >
                {element.text}
              </p>
            </div>
            {images[index + 1] && (
              <div
                className={`image ${index}`}
                style={{ backgroundImage: `url(${images[index + 1]})` }}
              />
            )}
          </>
        );
      })}
    </div>
  ) : (
    <div
      className={`text-select-container ${right ? "" : "reversed"}`}
      style={{ backgroundImage: `url(${images[selected]})` }}
    >
      <div
        className="overlay-container"
        style={
          right ? {} : { color: "black", backgroundColor: "white", left: 0 }
        }
      >
        <div className={`text-container ${short && "short"}`}>
          <TitleComponent
            title1={content.title1}
            title2={content.title2}
            bulgarianSpecial={small}
          />
          {content.innerContent.map((el, index) => {
            return (
              <div
                className={`content-text-container ${
                  1 + index === content.innerContent.length && "last"
                }`}
                onClick={() => setSelected(index)}
              >
                <h3
                  id={index === selected ? "selectedTitle" : ""}
                  style={
                    (chosenLanguage === "bg" && {
                      fontFamily: index === selected ? "bold" : "regular",
                    }) ||
                    {}
                  }
                >
                  {el.subtitle}
                </h3>
                {isArray(el.text) ? (
                  el.text.map((el) => {
                    return (
                      <p
                        id={index === selected ? "selected" : ""}
                        style={
                          (chosenLanguage === "bg" && {
                            fontFamily: index === selected ? "regular" : "thin",
                          }) ||
                          {}
                        }
                      >
                        {el}
                      </p>
                    );
                  })
                ) : (
                  <p
                    id={index === selected ? "selected" : ""}
                    style={
                      (chosenLanguage === "bg" && {
                        fontFamily: index === selected ? "regular" : "thin",
                      }) ||
                      {}
                    }
                  >
                    {el.text}
                  </p>
                )}
                {index === selected && <div className="select-line" />}
              </div>
            );
          })}
          {content.additionalInfo.map((el) => {
            return (
              <ViewMoreComponent
                textBold={el.textBold}
                textLight={el.textLight}
                right={!right}
                onClick={() => {
                  if (
                    el.textBold === "CONTACT" ||
                    el.textBold === "СВЪРЖЕТЕ СЕ"
                  ) {
                    onScroll();
                  } else {
                    window.open(content.link);
                  }
                }}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TextSelectionComponent;
