import React from "react";
import { ViewMoreComponent, TitleComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function HomeGridGallery() {
  const chosenLanguage = localStorage.getItem("code");
  return (
    <div className="grid-gallery-home-container">
      <div className="left-container">
        <div className="flex-column">
          <div className="flex-container top">
            <div className="grid-image one" />
            <div className="grid-image two" />
            <div className="grid-image three" />
          </div>
          <div className="flex-container">
            <div className="grid-image four" />
            <div className="grid-image five" />
            <div className="grid-image six" />
          </div>
          <div className="flex-container bottom">
            <div className="grid-image seven" />
            <div className="grid-image eight" />
            <div className="grid-image nine" />
          </div>
        </div>
      </div>
      <div className="right-container">
        <div className="text-container">
          <TitleComponent title1="SOCIAL" title2="THIS WAY" />
          <h3 style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}>
            {translate("homeSubText5")}
          </h3>
          <p>Stay up to date with us on Instagram, Facebook and LinkedIn</p>
          <ViewMoreComponent
            textBold={translate("homeSocial1")}
            textLight="INSTAGRAM"
            right
            onClick={() =>
              window.open("https://www.instagram.com/thiswaysofia/")
            }
          />
          <ViewMoreComponent
            textBold={translate("homeSocial1")}
            textLight="FACEBOOK"
            right
            onClick={() =>
              window.open("https://www.facebook.com/thisway.coworking")
            }
          />
          <ViewMoreComponent
            textBold={translate("homeSocial1")}
            textLight="LINKEDIN"
            right
            onClick={() =>
              window.open("https://www.linkedin.com/company/workthisway/")
            }
          />
          <ViewMoreComponent
            textBold={translate("homeSocial1")}
            textLight="YOUTUBE"
            right
            onClick={() =>
              window.open(
                "https://www.youtube.com/channel/UCD_6OK3_nBH6mbRsrl0IBtw"
              )
            }
          />
        </div>
      </div>
    </div>
  );
}

export default HomeGridGallery;
