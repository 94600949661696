import React, { useRef } from "react";
import {
  EventsComponentWhite,
  TourComponent,
  MainTextComponent,
  TextSelectionComponent,
  ContactForm,
} from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function Events() {
  const contactUs = useRef(null);
  return (
    <div className="events-container">
      <EventsComponentWhite
        onScroll={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      <TourComponent
        dimmed
        title="YOGA"
        title2="THIS WAY"
        text="EVENT OF THE MONTH"
        video="https://thiswaywebsite.s3.eu-central-1.amazonaws.com/videos/yoga/playlist.m3u8"
        smallerVideo
      />
      <MainTextComponent
        noFlex
        switched
        right
        image="/imgs/tailored-event.jpg"
        content={{
          title1: "TAILORED",
          title2: "EVENTS",
          text: [
            {
              text: translate("eventsText2"),
              additionalInfo: [
                {
                  textBold: translate("eventsButton1"),
                  textLight: translate("eventsButton2"),
                },
                {
                  textBold: translate("buttonText5"),
                  textLight: translate("buttonText6"),
                },
              ],
            },
          ],
        }}
        onScroll={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      <TextSelectionComponent
        special
        mobileSmaller
        images={
          window.innerWidth < 900
            ? [null, null, null, null, null, null, "/imgs/services-2.jpg"]
            : [
                "/imgs/services-2.jpg",
                "/imgs/services-2.jpg",
                "/imgs/services-2.jpg",
                "/imgs/services-2.jpg",
                "/imgs/services-2.jpg",
                "/imgs/services-2.jpg",
                "/imgs/services-2.jpg",
              ]
        }
        content={{
          title1: "SERVICES",
          title2: "",
          innerContent: [
            {
              subtitle: translate("eventsSubTitle2"),
              text: translate("eventsSubText2"),
            },
            {
              subtitle: translate("eventsSubTitle3"),
              text: translate("eventsSubText3"),
            },
            {
              subtitle: translate("eventsSubTitle4"),
              text: translate("eventsSubText4"),
            },
            {
              subtitle: translate("eventsSubTitle5"),
              text: translate("eventsSubText5"),
            },
            {
              subtitle: translate("eventsSubTitle6"),
              text: translate("eventsSubText6"),
            },
            {
              subtitle: translate("eventsSubTitle7"),
              text: translate("eventsSubText7"),
            },
            {
              subtitle: translate("eventsSubTitle8"),
              text: translate("eventsSubText8"),
            },
          ],
          additionalInfo: [
            {
              textBold: translate("buttonText5"),
              textLight: translate("buttonText6"),
            },
          ],
        }}
        onScroll={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      {/* <EventsComponentBlack /> */}
      <div ref={contactUs}>
        <ContactForm />
      </div>
    </div>
  );
}

export default Events;
