export const LanguageTypes = {
  SET_LANGUAGE: "language/SET_LANGUAGE",
  GET_LANGUAGES: "language/GET_LANGUAGES",
  GET_LANGUAGES_SUCCESS: "language/GET_LANGUAGES_SUCCESS",
  SET_LANGUAGE_TO_LOCAL_STORAGE: "language/SET_LANGUAGE_TO_LOCAL_STORAGE",
};

export const setLanguage = (payload) => ({
  type: LanguageTypes.SET_LANGUAGE,
  payload,
});

export const getLanguages = () => ({
  type: LanguageTypes.GET_LANGUAGES,
});

export const getLanguagesSuccess = (payload) => ({
  type: LanguageTypes.GET_LANGUAGES_SUCCESS,
  payload,
});

export const setLanguageToLocalStorage = (payload) => ({
  type: LanguageTypes.SET_LANGUAGE_TO_LOCAL_STORAGE,
  payload,
});
