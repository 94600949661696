import React, { useState } from "react";
import "./styles.scss";

function DropdownMobile({ selectedOption, options, onClick, inverted }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selected, setSelected] = useState(selectedOption);
  return (
    <div className="dropdown-mobile-container">
      <div
        className={`unopened-container ${inverted ? "inverted" : ""}`}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      >
        <div className="dummy" />
        <div className={`selected-region ${inverted ? "inverted" : ""}`}>
          {selected}
        </div>
        <div className={`arrow-btn ${inverted ? "inverted" : ""}`} />
      </div>
      {dropdownOpen ? (
        <div className={`opened-container ${inverted ? "inverted" : ""}`}>
          {options.map((option) => {
            return (
              <div
                className={`menu-element ${
                  option === selected ? "selected" : ""
                } ${inverted ? "inverted" : ""}`}
                onClick={() => {
                  onClick(option);
                  setSelected(option);
                  setDropdownOpen(false);
                }}
              >
                {option}
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}

export default DropdownMobile;
