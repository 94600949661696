import React from "react";
import {
  MainTextComponent,
  AboutSwitchComponent,
  MeetTeamComponent,
  ContactForm,
} from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function AboutThisWay() {
  return (
    <div className="about-us-container">
      <MainTextComponent
        switched
        noMarginParagraph
        specialVideo
        video="https://thiswaywebsite.s3.eu-central-1.amazonaws.com/videos/timelapse/playlist.m3u8"
        content={{
          title1: "ABOUT",
          title2: "THIS WAY",
          text: [
            {
              subtitle: "Coworking. Seriously.",
              text: translate("aboutThisWayText"),
              additionalInfo: [],
            },
            {
              subtitle: "",
              text: translate("aboutThisWayText2"),
              additionalInfo: [],
            },
          ],
        }}
      />
      <AboutSwitchComponent />
      <MeetTeamComponent />
      <ContactForm />
    </div>
  );
}

export default AboutThisWay;
