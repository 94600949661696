import React, { useState, useEffect } from "react";
import { TitleComponent } from "../../components";
import { Base64 } from "js-base64";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import "./styles.scss";

function ContactForm({ reversed }) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [details, setDetails] = useState("");
  const [phone, setPhone] = useState("");
  const [formSent, setFormSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coworkingChecked, setCoworkingChecked] = useState(false);
  const [servicedOffice, setServicedOffice] = useState(false);
  const [meetingRooms, setMeetingRooms] = useState(false);
  const [eventSpace, setEventSpace] = useState(false);
  const [cookies, setCookies] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [message, setMessage] = useState("");
  const [loadingMessage, setLoadingMessage] = useState(
    "Please wait several seconds."
  );
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        let newLoadingMessage = loadingMessage + ".";
        if (newLoadingMessage === "Please wait several seconds.....") {
          newLoadingMessage = "Please wait several seconds.";
        }
        setLoadingMessage(newLoadingMessage);
      }, 500);
    }
  }, [loading, loadingMessage]);
  return (
    <div className={`contact-form-container ${reversed ? "reversed" : ""}`}>
      <div className={`overlay ${reversed ? "reversed" : ""}`}>
        <div className="text-container">
          <TitleComponent title1="INQUIRE" />
          {!formSent && (
            <>
              <div className="flex-container top">
                <p id="lighter">E: info@thisway.com</p>
                <p id="lighter">T: +359 2 9264264</p>
              </div>
              <div className="flex-container">
                <h1 id="bold">INQUIRY</h1>
                <h1 id="light">FORM</h1>
              </div>
            </>
          )}

          {formSent ? (
            <div className={`sent-form-container ${reversed && "reversed"}`}>
              Thank You, your Message was successfuly sent! We will get back to
              you shortly!
            </div>
          ) : (
            <div className="form-container">
              <div className="left-container">
                <div
                  className={`form-input-wrapper margin-right ${
                    reversed ? "reversed" : ""
                  }`}
                >
                  <input
                    className="form-input"
                    disabled={loading}
                    type="text"
                    value={name}
                    placeholder="Your Name*"
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div
                  className={`form-input-wrapper ${reversed ? "reversed" : ""}`}
                >
                  <input
                    className="form-input"
                    disabled={loading}
                    type="text"
                    value={surname}
                    placeholder="Surname*"
                    onChange={(event) => setSurname(event.target.value)}
                  />
                </div>
                <div
                  className={`form-input-wrapper margin-right ${
                    reversed ? "reversed" : ""
                  }`}
                >
                  <input
                    className="form-input"
                    disabled={loading}
                    type="text"
                    value={company}
                    placeholder="Company Name"
                    onChange={(event) => setCompany(event.target.value)}
                  />
                </div>
                <div
                  className={`form-input-wrapper margin-right ${
                    reversed ? "reversed" : ""
                  }`}
                >
                  <input
                    className="form-input"
                    disabled={loading}
                    type="text"
                    value={email}
                    placeholder="Email*"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div
                  className={`form-input-wrapper margin-right phone ${
                    reversed ? "reversed" : ""
                  }`}
                >
                  <PhoneInput
                    country={"bg"}
                    value={phone}
                    containerClass={reversed && "reversed"}
                    onChange={(value) => setPhone(value)}
                  />
                  {/* <div
                    className="phone-dropdown"
                    onClick={() => setPhoneCode("+359")}
                  >
                    <div className="country-icon" />
                    <p>{phoneCode}</p>
                    <div className="arrow-down-phone" />
                  </div>
                  <input
                    className="form-input"
                    disabled={loading}
                    type="text"
                    value={phone}
                    placeholder="Phone*"
                    onChange={(event) => setPhone(event.target.value)}
                  /> */}
                </div>
                <div
                  className={`form-input-wrapper ${reversed ? "reversed" : ""}`}
                >
                  <input
                    className="form-input"
                    disabled={loading}
                    type="text"
                    value={website}
                    placeholder="Website"
                    onChange={(event) => setWebsite(event.target.value)}
                  />
                </div>
                <div
                  className={`form-input-wrapper ${reversed ? "reversed" : ""}`}
                >
                  <input
                    className="form-input"
                    disabled={loading}
                    type="text"
                    value={address}
                    placeholder="Address"
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </div>
              </div>
              <div className="right-container">
                <div
                  className={`form-textarea-wrapper margin-right ${
                    reversed ? "reversed" : ""
                  }`}
                >
                  <textarea
                    disabled={loading}
                    value={details}
                    placeholder="Share your thoughts"
                    onChange={(event) => setDetails(event.target.value)}
                  />
                </div>
                <p id="margin">I'm interested in*</p>
                <div className="checkbox-element-container">
                  <div
                    className={`checkbox-container ${reversed && "reversed"} ${
                      coworkingChecked && `checked ${reversed && "reversed"}`
                    }`}
                    onClick={() => setCoworkingChecked((prev) => !prev)}
                  >
                    {coworkingChecked && <div className="checkmark" />}
                  </div>
                  <p>Coworking</p>
                </div>
                <div className="checkbox-element-container">
                  <div
                    className={`checkbox-container ${reversed && "reversed"} ${
                      servicedOffice && `checked  ${reversed && "reversed"}`
                    }`}
                    onClick={() => setServicedOffice((prev) => !prev)}
                  >
                    {servicedOffice && <div className="checkmark" />}
                  </div>
                  <p>Serviced Office</p>
                </div>
                <div className="checkbox-element-container">
                  <div
                    className={`checkbox-container ${reversed && "reversed"} ${
                      meetingRooms && `checked ${reversed && "reversed"}`
                    }`}
                    onClick={() => setMeetingRooms((prev) => !prev)}
                  >
                    {meetingRooms && <div className="checkmark" />}
                  </div>
                  <p>Meeting Rooms</p>
                </div>
                <div className="checkbox-element-container">
                  <div
                    className={`checkbox-container ${reversed && "reversed"} ${
                      eventSpace && `checked ${reversed && "reversed"}`
                    }`}
                    onClick={() => setEventSpace((prev) => !prev)}
                  >
                    {eventSpace && <div className="checkmark" />}
                  </div>
                  <p>Event Space</p>
                </div>
                <div className="checkbox-element-container">
                  <div
                    className={`checkbox-container ${reversed && "reversed"} ${
                      coworkingChecked &&
                      servicedOffice &&
                      meetingRooms &&
                      eventSpace &&
                      `checked ${reversed && "reversed"}`
                    }`}
                    onClick={() => {
                      if (
                        coworkingChecked &&
                        servicedOffice &&
                        meetingRooms &&
                        eventSpace
                      ) {
                        setCoworkingChecked(false);
                        setServicedOffice(false);
                        setMeetingRooms(false);
                        setEventSpace(false);
                      } else {
                        setCoworkingChecked(true);
                        setServicedOffice(true);
                        setMeetingRooms(true);
                        setEventSpace(true);
                      }
                    }}
                  >
                    {coworkingChecked &&
                      servicedOffice &&
                      meetingRooms &&
                      eventSpace && <div className="checkmark" />}
                  </div>
                  <p>All of the above</p>
                </div>
                <div className="checkbox-element-container margin-top">
                  <div
                    className={`checkbox-container ${reversed && "reversed"} ${
                      cookies && `checked ${reversed && "reversed"}`
                    }`}
                    onClick={() => setCookies((prev) => !prev)}
                  >
                    {cookies && <div className="checkmark" />}
                  </div>
                  <p>Agree to cookies*</p>
                </div>
                <div className="checkbox-element-container">
                  <div
                    className={`checkbox-container  ${reversed && "reversed"} ${
                      privacyPolicy && `checked ${reversed && "reversed"}`
                    }`}
                    onClick={() => setPrivacyPolicy((prev) => !prev)}
                  >
                    {privacyPolicy && <div className="checkmark" />}
                  </div>
                  <p>Agree to Privacy Policy*</p>
                </div>
                <div className="flex-end">
                  <div
                    className="send-button"
                    onClick={() => {
                      if (!loading && email && phone) {
                        setLoading(true);
                        setSuccess(false);
                        window
                          .fetch(
                            "https://mailer.fidweb.net/mail/send/thisway",
                            {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                                Authorization:
                                  "Basic " +
                                  Base64.encode("thisway:FSAr237y8r&Gafs9i"),
                              },
                              body: JSON.stringify({
                                from: email,
                                to: ["ella@thisway.com", "info@thisway.com"],
                                subject: "subject",
                                body: ` Изпратено от: ${name} ${surname},\n Email: ${email}, \n Компания: ${company}, \n Телефон: ${phone},\n Сайт: ${website}, \n Адрес: ${address}, \n Съобщение: ${details}, Интереси: ${
                                  coworkingChecked && "- Съвместна работа"
                                } ${servicedOffice && " - Офис"} ${
                                  meetingRooms && " - Стая за среща"
                                } ${eventSpace && " - Място за събитие"}`,
                                notifyBody: ` 
                          <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
                          <html xmlns="http://www.w3.org/1999/xhtml">
                          <head>
                            <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
                            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                            <title>THISWAY</title>
                            <link href="https://fonts.googleapis.com/css?family=Cormorant+Infant:300,400,500,600,700|Montserrat:200,300,400,500,600,700,800,900&amp;subset=cyrillic,cyrillic-ext,latin-ext" rel="stylesheet">
                            <style type="text/css">
                              * {font-family: 'Montserrat', Arial, Helvetica, sans-serif;}
                              #outlook a {padding: 0;}
                              body {width: 100% !important; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; margin: 0; padding: 0; }
                              img {outline: none; text-decoration: none; -ms-interpolation-mode: bicubic;}
                              a img {border: none; }
                              h1, h2, h3, h4, h5, h6,
                              h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
                              h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
                              h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited {font-family: 'Cormorant Infant', Arial, Helvetica, sans-serif; color: #201E18;}
                              a {outline: none; text-decoration: none; color: #DFC475; }
                              @media only screen and (max-width:600px) {
                                *[class].full {width: 100% !important; overflow: hidden !important;}
                                *[class].cen {margin: 0 auto !important; text-align: center !important;}
                                *[class].img-full {display: block !important; width: 100% !important; height: auto !important;}
                              }
                            </style>
                          </head>
                          <body style="margin:0;padding:0;background-color:#ffffff;font-family:'Montserrat',Arial,Helvetica,sans-serif;font-size:16px;">
                            <table width="100%" cellpadding="0" cellspacing="0" border="0" bgcolor="#ffffff">
                              <tr>
                                <td valign="top" bgcolor="#ffffff" align="center" width="100%">
                                  <table cellpadding="0" cellspacing="0" border="0" align="center" valign="top" width="600" class="full">
                                    <tr>
                                      <td valign="top">
                                          <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="pix.gif" height="20" width="1" border="0" style="display:block;" /></td></tr></table>
                                          <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                            <tr>
                                              <td><img src="" width="10" height="1" /></td>
                                              <td style="text-align:left;font-size:12px;color:#201E18;font-family:'Montserrat',Arial,Helvetica,sans-serif;">${name}</td>
                                              <td style="text-align:right;font-size:12px;color:#201E18;font-family:'Montserrat',Arial,Helvetica,sans-serif;">date ${moment().format(
                                                "DD/MM/YYYY"
                                              )}г.</td>
                                              <td><img src="" width="10" height="1" /></td>
                                            </tr>
                                            <tr><td colspan="4"><img src="" width="10" height="10" /></td></tr>
                                            <tr><td align="center" colspan="4" style="text-align: center;font-family:'Montserrat',Arial,Helvetica,sans-serif;"><a href="https://thisway-website.herokuapp.com" target="_blank" style="text-decoration:none;font-size:21px;color:#DFC475;font-weight:bold;font-family: 'Cormorant Infant', Arial, Helvetica, sans-serif; "><img src=""alt="THIS WAY" border="0" /></a></td></tr>
                                          </table>
                                          <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="" height="10" width="1" border="0" style="display:block;" /></td></tr></table>
                                          <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                            <tr><td align="center"><a href="https://thisway-website.herokuapp.com" target="_blank" style="text-decoration:none;font-family:'Montserrat',Arial,Helvetica,sans-serif;"><img src=""alt="THIS WAY" border="0" class="img-full" /></a></td></tr>
                                          </table>
                                          <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                            <tr>
                                              <td><img src="" width="10" height="1" /></td>
                                              <td style="text-align: left">
                                                <h1 style="font-size:34px;line-height:24px;color:#201E18;font-family: 'Cormorant Infant', Arial, Helvetica, sans-serif; ">ThisWay</h1>
                                                <p style="font-size:14px;line-height:24px;color:#201E18;font-family:'Montserrat',Arial,Helvetica,sans-serif;"></p>
                                                <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                                  <tr>
                                                      <td><img src="" width="20" height="1" /></td>
                                                      <td align="left">
                                                          <p style="font-size:14px;line-height:24px;color:#201E18;font-family:'Montserrat',Arial,Helvetica,sans-serif;">
                                                            Sent by: ${name} ${surname}<br/>
                                                            E-mail: ${email}<br/>
                                                            Phone: ${phone}<br/>
                                                            Company Name: ${company}<br/>
                                                            Website: ${website}<br/>
                                                            Address: ${address}<br/>
                                                            Message: ${details}
                                                          </p>
                                                      </td>
                                                    </tr>
                                                </table>
                          
                                                <p style="font-size:14px;line-height:24px;color:#201E18;font-family:'Montserrat',Arial,Helvetica,sans-serif;">
                                                 something
                                                </p>
                                              </td>
                                              <td><img src="" width="10" height="1" /></td>
                                            </tr>
                                          </table>
                                          <table cellpadding="0" cellspacing="0" border="0" width="100%">
                                              <tr>
                                                <td style="text-align:center;">
                                                  <p style="text-align:center;font-size:11px;color:#DFC475;font-family:'Montserrat',Arial,Helvetica,sans-serif;"><a href="https://thisway-website.herokuapp.com" target="_blank" style="text-decoration:none;font-size:11px;color:#DFC475;font-weight:bold;font-family: 'Cormorant Infant', Arial, Helvetica, sans-serif; "><img src=""alt="THIS WAY" border="0" /></a></p>
                                                </td>
                                              </tr>
                                            </table>
                                            <table cellpadding="0" cellspacing="0" border="0" width="100%"><tr><td><img src="" height="40" width="1" border="0" style="display:block;" /></td></tr></table>
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </body>
                          </html>
                          `,
                                notifySubject: `ThisWay`,
                              }),
                            }
                          )
                          .then((result) => {
                            setSuccess(true);
                            setFormSent(true);
                            setLoading(false);
                          })
                          .catch((error) => {
                            setSuccess(true);
                            setLoading(false);
                            console.log(error);
                          });
                      }
                    }}
                  >
                    <p>SUBMIT</p>
                    <div
                      className={`arrow-right ${reversed ? "reversed" : ""}`}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        {loading && <p id="loading-text">{loadingMessage}</p>}
      </div>
      {window.innerWidth < 900 ? <div className="contact-form-image" /> : null}
    </div>
  );
}

export default ContactForm;
