import React from "react";

import "./styles.scss";

function ViewMoreComponent({
  textBold,
  textLight,
  right,
  margin,
  switched,
  noFlex,
  onClick,
  arrowDown,
}) {
  const chosenLanguage = localStorage.getItem("code");
  return (
    <div
      className="view-more-container"
      style={
        margin
          ? { marginBottom: "60px" }
          : noFlex
          ? { marginBottom: "20px" }
          : {}
      }
      onClick={onClick}
    >
      <span
        id="bold"
        style={(chosenLanguage === "bg" && { fontFamily: "bold" }) || {}}
      >
        {textBold}
      </span>
      <span
        id="light"
        style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}
      >
        {textLight}
      </span>
      <div
        className={`arrow-right ${
          right ? (switched ? "white" : "black") : switched ? "black" : "white"
        } ${arrowDown ? "down" : ""}`}
      />
    </div>
  );
}

export default ViewMoreComponent;
