import React, { useRef } from "react";
import {
  BenefitsComponent,
  TourComponent,
  BenefitsText,
  TestimonialsComponent,
  ContactForm,
} from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function WhyThisWay() {
  const contactUs = useRef(null);
  return (
    <div className="why-this-way-container">
      <BenefitsComponent />
      <TourComponent
        title={translate("benefitsTourTitle1")}
        title2={translate("benefitsTourTitle2")}
        text2={translate("benefitsTourSubTitle")}
        img="/imgs/tour-day.png"
        dimmed
      />
      <BenefitsText />
      <TestimonialsComponent
        onScroll={() =>
          contactUs.current.scrollIntoView({
            block: "center",
            inline: "center",
            behavior: "smooth",
          })
        }
      />
      <div ref={contactUs}>
        <ContactForm />
      </div>
    </div>
  );
}

export default WhyThisWay;
