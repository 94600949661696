import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import "./styles.scss";

function HeaderDropdown({ subElements, onClick, language, smaller }) {
  return (
    <div className="sub-menu">
      {subElements.map((el, index) => {
        return (
          <NavLink to={`/${language}/${el.link}`}>
            <div
              className={`events ${
                index === subElements.length - 1 ? "no-border" : ""
              } ${smaller ? "smaller" : ""}`}
              onClick={onClick}
            >
              {el.text}
            </div>
          </NavLink>
        );
      })}
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.selectedLanguage,
});

export default connect(mapStateToProps, null)(HeaderDropdown);
