import React, { useState } from "react";
import {
  TabSwitch,
  ViewMoreComponent,
  TitleComponent,
  DropdownMobile,
} from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

const images = [
  "/imgs/kingkong-min.jpg",
  "/imgs/superman-min.jpg",
  "/imgs/wonderwoman-min.jpg",
  "/imgs/spiderman-min.jpg",
];

function MeetingsComponentWhite({ onScroll }) {
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedText, setSelectedText] = useState(
    translate("meetingsTabText1-1")
  );
  const chosenLanguage = localStorage.getItem("code");
  const [author, setAuthor] = useState(translate("meetingsTabPerson1"));
  const [capacity, setCapacity] = useState(translate("meetingsTabText1-2"));
  return (
    <div
      className="meetings-white-container"
      style={{ backgroundImage: `url(${images[imageIndex]})` }}
    >
      <div className="overlay-white">
        <div className="text-container">
          <TitleComponent title1="MEETING" title2="ROOMS" />
          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {translate("meetingsText1")}
          </p>
          {window.innerWidth < 900 ? (
            <>
              <div
                className="meetings-mobile-image"
                style={{ backgroundImage: `url(${images[imageIndex]})` }}
              />
              <DropdownMobile
                selectedOption="King Kong"
                options={["King Kong", "Superman", "Wonder Woman", "Spiderman"]}
                onClick={(value) => {
                  switch (value) {
                    case "King Kong":
                      setImageIndex(0);
                      setSelectedText(translate("meetingsTabText1-1"));
                      setAuthor(translate("meetingsTabPerson1"));
                      setCapacity(translate("meetingsTabText1-2"));
                      break;
                    case "Superman":
                      setImageIndex(1);
                      setSelectedText(translate("meetingsTabText2-1"));
                      setAuthor(translate("meetingsTabPerson2"));
                      setCapacity(translate("meetingsTabText2-2"));
                      break;
                    case "Wonder Woman":
                      setImageIndex(2);
                      setSelectedText(translate("meetingsTabText3-1"));
                      setAuthor(translate("meetingsTabPerson3"));
                      setCapacity(translate("meetingsTabText3-2"));
                      break;
                    case "Spiderman":
                      setImageIndex(3);
                      setSelectedText(translate("meetingsTabText4-1"));
                      setAuthor(translate("meetingsTabPerson4"));
                      setCapacity(translate("meetingsTabText4-2"));
                      break;
                    default:
                      break;
                  }
                }}
              />
            </>
          ) : (
            <TabSwitch
              selected="King Kong"
              buttons={["King Kong", "Superman", "Wonder Woman", "Spiderman"]}
              onSelect={(value) => {
                switch (value) {
                  case "King Kong":
                    setImageIndex(0);
                    setSelectedText(translate("meetingsTabText1-1"));
                    setAuthor(translate("meetingsTabPerson1"));
                    setCapacity(translate("meetingsTabText1-2"));
                    break;
                  case "Superman":
                    setImageIndex(1);
                    setSelectedText(translate("meetingsTabText2-1"));
                    setAuthor(translate("meetingsTabPerson2"));
                    setCapacity(translate("meetingsTabText2-2"));
                    break;
                  case "Wonder Woman":
                    setImageIndex(2);
                    setSelectedText(translate("meetingsTabText3-1"));
                    setAuthor(translate("meetingsTabPerson3"));
                    setCapacity(translate("meetingsTabText3-2"));
                    break;
                  case "Spiderman":
                    setImageIndex(3);
                    setSelectedText(translate("meetingsTabText4-1"));
                    setAuthor(translate("meetingsTabPerson4"));
                    setCapacity(translate("meetingsTabText4-2"));
                    break;
                  default:
                    break;
                }
              }}
            />
          )}
          <p
            id="italic"
            style={
              (chosenLanguage === "bg" && { fontFamily: "medium-italic" }) || {}
            }
          >
            "{selectedText}"
          </p>
          <p
            id="author"
            style={
              (chosenLanguage === "bg" && { fontFamily: "medium-italic" }) || {}
            }
          >
            {author}
          </p>
          <p
            id="capacity"
            style={(chosenLanguage === "bg" && { fontFamily: "regular" }) || {}}
          >
            {capacity}
          </p>
          <ViewMoreComponent
            right
            textBold={translate("buttonText5")}
            textLight={translate("buttonText6")}
            onClick={() => onScroll()}
            noFlex
          />
          <ViewMoreComponent
            right
            textBold={translate("buttonText3")}
            textLight={translate("buttonText4")}
            onClick={() => {
              window.open("https://mpembed.com/show/?m=UsxETWZnxaW");
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default MeetingsComponentWhite;
