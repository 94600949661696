import React from "react";
import "./styles.scss";

function PrivacyPolicy() {
  const language = localStorage.getItem("code");
  console.log(language);
  return (
    <div className="policy-container">
      <h1>PRIVACY POLICY</h1>
      <h1>of THIS WAY SOFIA LIMITED EOOD</h1>
      <p>
        Thank you for visiting our webpage. The present Privacy Policy
        (hereinafter referred to as the Policy) aims to inform you about the way
        we process your personal data, collected through this webpage.
      </p>
      <p id="bold">1. Personal Data Controller</p>
      <p>
        THIS WAY SOFIA LIMITED EOOD (referred to hereinbelow as THIS WAY or the
        Controller) is a personal data controller. The company is registered at
        the Bulgarian Commercial Register with UIC 205103287, having its seat
        and registered address at: 2A Saborna Str., fl. 3, Sredets Region, 1000
        Sofia and operating address: Office A, Foor 4, 2 Ivan Abadjiev Str.,
        Sofia 1784.
      </p>
      <p>
        In order to be most useful through its webpage, in certain cases THIS
        WAY processes personal data of users of the webpage and in that capacity
        is personal data controller within the meaning of the Regulation
        2016/679 of the European Parliament and of the Council of 27 April 2016
        (General Data Protection Regulation).
      </p>
      <p>
        The security of your information is an important part of the THIS WAY’s
        internal policy. As a controller THIS WAY considers as a matter of
        priority the issues of protection of your personal data collected during
        your visit of our webpage and the processing and storing processes in
        compliance with the legal requirements.
      </p>
      <p id="bold">2. Contact Details of the Controller</p>
      <p>You may contact the Controller by any of the following means:</p>
      <li> in writing at the following addresses:</li>
      <p>- 2A, Saborna Str., fl. 3, Sredets Region, 1000 Sofia;</p>
      <p>- Office A, Floor 4, 2 Ivan Abadjiev Street, 1784 Sofia;</p>
      <li> by telephone number: +359 2 926 4264;</li>
      <li> +359 2 926 4111; by е-mail: info@thisway.com;</li>
      <li>
        via website: <a href="https://www.thisway.com">www.thisway.com</a>
      </li>
      <p id="bold">
        3. Personal Data We Collect About You. Purposes and Grounds for Their
        Processing
      </p>
      <p>
        The use of the information on our webpage does not imply the provision
        of personal data by you. The website contains the following
        functionalities through which THIS WAY may collect and process your
        personal data:
      </p>
      <p id="bold">3.1. Get In Touch Form</p>
      <p>
        The personal data collected and processed by THIS WAY through the
        Inquiry “Get in Touch Form” option are: name, position, company,
        address, e-mail, phone number and other data that you have decided to
        provide with your inquiry.
      </p>
      <p id="bold">
        3.2. Book a Viewing Get in Touch (Serviced Offices, Monthly fees
        inclusive of and box to click)
      </p>
      <p>
        The data: name, position, company, e-mail, phone number and other data
        that you have decided to provide to us, are also collected and processed
        when you use the option Book a Viewing, which allows you to request
        arrangement of a viewing with regard to some of the services we provide.
      </p>
      <p id="bold">3.3. Call Back Form</p>
      <p>
        The use of the Call Back option requires the following personal data in
        order for Akarie to be able to return you a call at a time appropriate
        for you so that you can receive information regarding the services
        Akarie offers: name, company, e-mail, phone number.
      </p>
      <p id="bold">3.4. Contact Form</p>
      <p>
        The specified personal data – name and e-mail, are collected and
        processed in case you send us a message via the Contact Form.
      </p>
      <p>
        Your personal data collected through the website options specified in
        it. 3.1., it. 3.2., it. 3.3. and it. 3.4. hereinabove is processed for
        the following purposes:
      </p>
      <li>To be able to answer the inquiry you have sent us;</li>
      <li>
        To be able to arrange a viewing of the respective office premises
        according to your needs and the services you wish to use;
      </li>
      <li>
        To be able to return a call and contact you so that you are provided
        with the information you need;
      </li>
      <li> To be able to answer the message you have sent us.</li>
      <p>
        The legal ground for processing of your personal data in these cases is
        your explicit, voluntary consent, which we consider that you have
        provided by submitting us the Get In Touch Form inquiry, booking a
        viewing form, call back form or message.
      </p>
      <p>
        THIS WAY will process your personal data only for the time necessary to
        prepare and send an answer to your inquiry, respectively - to process
        your booking request, to contact you back by phone or e-mail, and within
        a 3-month period after the last correspondence with you, unless you have
        become our client, supplier or business partner, in which case the terms
        and conditions under the contract signed with you shall apply with
        regard to the processing of your personal data.
      </p>
      <li>
        We can use these data for direct marketing purposes as well – to inform
        you for the products and services THIS WAY offers or to invite you to
        events organized by us.
      </li>
      <p>
        In this case the ground for processing of your personal data is
        protection of THIS WAY’s legitimate business interests to present and
        promote its services. THIS WAY will process your personal data for that
        purpose until you object or until you opt-out [DPLF1] from receiving our
        direct marketing messages via our e-mail: info@thisway.com.
      </p>
      <p id="bold">
        3.5. Events Organized by/ or with the Participation of THIS WAY
      </p>
      <p>
        As part of its business activity THIS WAY organizes and/ or participates
        in various corporative events such as conferences, seminars,
        presentations, corporate celebrations and others, aiming to promote the
        services provided by THIS WAY and fulfilling the purposes of the
        corporate social responsibility policy adopted by THIS WAY.
      </p>
      <p>
        Chosen photos from these events may be published on the webpage of THIS
        WAY or in advertising materials published in media, social networks,
        catalogues, brochures, publications and others related to THIS WAY`s
        business activity in accordance with the adopted by THIS WAY business
        policy and advertisement strategy.
      </p>
      <p>
        THIS WAY`s employees, as well as third parties participating in the
        organized event may be identified on these photos. In certain cases the
        photos may be accompanied by explanatory text including the names of
        some of the participating persons.
      </p>
      <p>
        When THIS WAY organizes events or participates in such, the territory,
        within which shooting may occur, is being specified. At the entrance of
        the specified territory written information is placed regarding the
        carrying out of the shooting for the duration of the event and that by
        participating in the event or entering the specified territory the
        respective person voluntarily provides its personal data, obtained
        through his/ her image shooting.
      </p>
      <p>
        In the hypotheses specified hereinabove the personal data processing is
        performed by THIS WAY for the following purposes:
      </p>
      <li> for promotion of the services, provided by THIS WAY;</li>
      <li>
        for fulfillment of the corporate social responsibility policy adopted by
        THIS WAY.
      </li>
      <p>
        The grounds for personal data processing in these hypotheses are:
        protection of THIS WAY`s legitimate interests to present and promote its
        services, as well as to realize its corporate social responsibility
        policy.
      </p>
      <p>
        The processing is performed until THIS WAY considers the data processing
        necessary for the above described purposes or until the respective
        person objects against such processing.
      </p>
      <p id="bold">3.6. Other Collected Data</p>
      <p>
        Besides the personal data collected by THIS WAY upon using certain
        functions of the webpage, the following data may be gathered
        additionally:
      </p>
      <li>
        Log files connected with the security, technical maintenance,
        development etc.
      </li>
      <p>
        Log files are files that save events in and interactions between systems
        and/ or between users and a given system. On our webpage we use log
        files for the following purposes: …………………………..
      </p>
      <li>
        THIS WAY uses "cookies" and other tracking technologies, which collect
        information about you upon interaction with our webpage, so that we can
        improve its performance and functionality.
      </li>
      <p>
        The cookies are small files, which may be saved on your computer or
        mobile device when you visit a webpage. The cookie will help the website
        or other websites to recognize your device the next time you visit it.
        Web beacons or other similar files can do the same thing. In this Policy
        we use the term cookie to name all files, which collect information in
        such a manner.
      </p>
      <p>
        More about the cookies, as well as the ways you can refuse the use of
        cookies on your device you can find in our
        <p id="link" onClick={() => console.log("download")}>
          Cookies Policy
        </p>
        [link to the Policy].
      </p>
      <p id="bold">4. Consequences of Non-provision of Personal Data</p>
      <p>
        When you intend to submit an inquiry or a request for provision of
        services, the provision of the data by you is completely voluntarily. In
        case you do not want to provide us with your personal data you will not
        be able to receive the desired services, as well as we will not be able
        to answer your inquiry.
      </p>
      <p id="bold">5. Disclosure of Your Personal Data </p>
      <p>
        THIS WAY may disclose your personal data to the following categories of
        recipients:
      </p>
      <li>
        state authorities, to which we are obligated by law to provide personal
        data;
      </li>
      <li>the company, entrusted by THIS WAY to maintain this webpage;</li>
      <li>
        people providing photo shooting services during the events organized by
        or with the participation of THIS WAY.
      </li>
      <p id="bold">
        6. Your rights with regard to the personal data processing{" "}
      </p>
      <p>
        With regard to the processing of your personal data by THIS WAY you are
        entitled to various rights to protect your interests. All rights listed
        herein below (with the exception of the right of complain to supervisory
        authority) you can exercise by filing a request to THIS WAY using the
        data provided in Section 2 “Contact details of the Controller”
        hereinabove. THIS WAY will make all necessary efforts to satisfy your
        request without unnecessary delay.
      </p>
      <p id="bold">6.1. Right of access:</p>
      <p>
        A main right of yours is at any time to receive a confirmation by THIS
        WAY whether your personal data are being processed, as well as to be
        provided with access to the processed data and information regarding the
        processing purposes, the categories personal data that are being
        processed, the recipients of your personal data, the processing period,
        the presence of automated decision-making and your rights with regard to
        the processing.
      </p>
      <p id="bold">6.2. Right of rectification: </p>
      <p>
        When you consider that the personal data processed about you are
        inaccurate you have the right to request THIS WAY to correct them, as
        well as to request to have your incomplete personal data completed.
      </p>
      <p id="bold">6.3. Right of erasure: </p>
      <p>
        As a subject, whose personal data are being processed by THIS WAY you
        are entitled to request from THIS WAY their erasure in the following
        cases:
      </p>
      <li>
        your personal data are no longer necessary for the purposes, for which
        they have been collected and processed;
      </li>
      <li>
        withdrawal of your consent, on the basis of which your personal data
        have been processed;
      </li>
      <li>your personal data are being processed unlawfully;</li>
      <li>
        you have objected against the processing of your personal data and there
        are no legal grounds for the processing, which shall prevail;
      </li>
      <li>
        the personal data must be erased for the purpose of compliance with the
        legislation of the European Union or of the Republic of Bulgaria.
      </li>
      <p id="bold">6.4. Right of restriction of the processing: </p>
      <p>
        Another right of yours with regard to the processing of your personal
        data by THIS WAY is to request, as far as the legal requirements are
        met, the restriction of the processing of your personal data by THIS WAY
        in the following cases:
      </p>
      <li>
        you dispute the accuracy of the personal data – for a period that allows
        THIS WAY to verify the accuracy of your data;
      </li>
      <li>
        the processing of your personal data is unlawful, but you do not want
        your personal data to be erased and only to restrict their use;
      </li>
      <li>
        THIS WAY no longer needs your personal data for the specified purposes,
        but you require their storage by THIS WAY whereby restriction of their
        processing is ensured, for the purposes of establishment, exercise or
        defense by you of legal claims.
      </li>
      <p id="bold">6.5. Right of objection against the data processing: </p>
      <p>
        As far as your personal data are subject to processing by THIS WAY you
        are entitled to object at any time against this processing. In this case
        THIS WAY will stop the processing of your personal data, unless there is
        another legal ground for their further processing, such as meeting legal
        requirements or presence of other legitimate interests that require the
        continuation of the processing.
      </p>
      <p id="bold">6.6. Right of withdrawal of your consent: </p>
      <p>
        The processing of your personal data by THIS WAY based on your explicit
        consent for that can be terminated with the withdrawal of your consent.
        This right of yours has immediate effect, however, it does not affect
        the lawfulness of the processing of your personal data performed up to
        the moment of the withdrawal of your consent.
      </p>
      <p id="bold">6.6. Right of data portability:</p>
      <p>
        You are entitled to receive a copy of your personal data that you have
        provided to THIS WAY, including, when possible, in electronic form. When
        the processing of your personal data is based on consent or on a
        contractual obligation and is performed in an automated manner, you can
        make a request to THIS WAY to receive the personal data in a structured,
        widely used, machinery-readable format. In this regard you can also
        request THIS WAY to transfer these data directly to another personal
        data controller upon your instruction.
      </p>
      <p id="bold">6.7. Right of complaint to a supervisory authority:</p>
      <p>
        If you consider that your personal data are being processed unlawfully
        or that your rights in connection with them have been violated, you are
        entitled to lodge a complaint to the competent Bulgarian supervisory
        authority – The Personal Data Protection Commission (the PDPC). You can
        contact the PDPC by using the specified herein below contact details:
      </p>
      <p>Address: 2 Prof. Tsvetan Lazarov Blvd., 1592 Sofia,</p>
      <p> Phone: 02/91-53-518,</p>
      <p> E-mail: kzld@cpdp.bg.</p>
      <p id="bold">6.8. Changes in the Present Policy</p>
      <p>
        THIS WAY can periodically change the present Privacy Policy, in order to
        comply with the legislative changes or practices and policies applied by
        THIS WAY. When we make such changes we change the date of the last
        update specified at the beginning of this Policy as well so that you can
        be able to get familiar with the current terms and conditions for
        processing of your data. We recommand the thick box for subscription to
        news and special offers on the website to remains unthicked.The
        paragraph shall remain as long as it is applicable.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
