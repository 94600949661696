import React, { useState } from "react";
import {
  TabSwitch,
  ViewMoreComponent,
  TitleComponent,
  DropdownMobile,
} from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

const images = [
  "/imgs/open-space.jpg",
  "/imgs/bar-meetings.png",
  "/imgs/Booths.jpg",
  "/imgs/phone-booths.png",
];

function MeetingsComponentBlack({ onScroll }) {
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedText, setSelectedText] = useState(
    translate("meetingSpacesText1")
  );
  const chosenLanguage = localStorage.getItem("code");
  return (
    <div
      className="meetings-black-container"
      style={{ backgroundImage: `url(${images[imageIndex]})` }}
    >
      <div className="overlay-black">
        <div className="text-container">
          <TitleComponent title1="MEETING" title2="SPACES" />
          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {translate("meetingSpacesSubTitle")}
          </p>
          {window.innerWidth < 900 ? (
            <DropdownMobile
              inverted
              selectedOption={translate("meetingSpacesTab1")}
              options={[
                translate("meetingSpacesTab1"),
                translate("meetingSpacesTab2"),
                translate("meetingSpacesTab3"),
                translate("meetingSpacesTab4"),
              ]}
              onClick={(value) => {
                switch (value) {
                  case translate("meetingSpacesTab1"):
                    setSelectedText(translate("meetingSpacesText1"));
                    setImageIndex(0);
                    break;
                  case translate("meetingSpacesTab2"):
                    setSelectedText(translate("meetingSpacesText2"));
                    setImageIndex(1);
                    break;
                  case translate("meetingSpacesTab3"):
                    setSelectedText(translate("meetingSpacesText3"));
                    setImageIndex(2);
                    break;
                  case translate("meetingSpacesTab4"):
                    setSelectedText(translate("meetingSpacesText4"));
                    setImageIndex(3);
                    break;
                  default:
                    break;
                }
              }}
            />
          ) : (
            <TabSwitch
              whiteTab
              selected={translate("meetingSpacesTab1")}
              buttons={[
                translate("meetingSpacesTab1"),
                translate("meetingSpacesTab2"),
                translate("meetingSpacesTab3"),
                translate("meetingSpacesTab4"),
              ]}
              onSelect={(value) => {
                switch (value) {
                  case translate("meetingSpacesTab1"):
                    setImageIndex(0);
                    setSelectedText(translate("meetingSpacesText1"));
                    break;
                  case translate("meetingSpacesTab2"):
                    setImageIndex(1);
                    setSelectedText(translate("meetingSpacesText2"));
                    break;
                  case translate("meetingSpacesTab3"):
                    setImageIndex(2);
                    setSelectedText(translate("meetingSpacesText3"));
                    break;
                  case translate("meetingSpacesTab4"):
                    setImageIndex(3);
                    setSelectedText(translate("meetingSpacesText4"));
                    break;
                  default:
                    break;
                }
              }}
            />
          )}

          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {selectedText}
          </p>
          <ViewMoreComponent
            textBold={translate("buttonText5")}
            textLight={translate("buttonText6")}
            onClick={() => onScroll()}
            noFlex
          />
          <ViewMoreComponent
            textBold={translate("buttonText3")}
            textLight={translate("buttonText4")}
            onClick={() =>
              window.open("https://mpembed.com/show/?m=UsxETWZnxaW")
            }
          />
        </div>
      </div>
    </div>
  );
}

export default MeetingsComponentBlack;
