import React from "react";
import { TitleComponent } from "../../components";
import { translate } from "../../translations/localization";
import "./styles.scss";

function MeetingsComponentWhite() {
  const chosenLanguage = localStorage.getItem("code");
  return (
    <div className="meet-team-container">
      <div className="left-container">
        <div className="text-container">
          <TitleComponent
            title1={translate("aboutThisWayMeetTitle1")}
            title2={translate("aboutThisWayMeetTitle2")}
            bulgarianSpecial={chosenLanguage === "bg"}
          />
          <p style={(chosenLanguage === "bg" && { fontFamily: "thin" }) || {}}>
            {translate("aboutThisWayMeetText")}
          </p>
        </div>
      </div>
      <div className="right-container">
        {window.innerWidth < 900 ? (
          <>
            <div className="flex-container">
              <div className="image one" />
              <div className="member-text-container">
                <h3>Ella</h3>
                <p>
                  "I love the large, openable windows and natural light in the
                  office"
                </p>
              </div>
            </div>
            <div className="flex-container">
              <div className="member-text-container">
                <p>
                  THIS WAY Hospitality and creating the best experience for our
                  clients is our priority
                </p>
              </div>
              <div className="image two" />
            </div>
            <div className="flex-container">
              <div className="image three" />
              <div className="member-text-container">
                <h3>Bori</h3>
                <p>
                  "The library walls are is by far my favorite feature - a
                  unique piece of art. ,I find it very refreshing and inspiring,
                  especially our our “take pick a book & , leave drop a book
                  initiative” concept and the amazing books we get to share and
                  read."
                </p>
              </div>
            </div>
            <div className="flex-container">
              <div className="member-text-container">
                <p>
                  Teamwork, Networking and Collaboration are essential - at THIS
                  WAY we Collaborate THIS WAY and you can too
                </p>
              </div>
              <div className="image four" />
            </div>
            <div className="flex-container">
              <div className="image five" />
              <div className="member-text-container">
                <h3>Giles</h3>
                <p>
                  "The stylish design features, such as this Eames Lounge Chair,
                  are my favorite parts about the office, they add a lot of
                  character as well as comfort to our valuable clients and
                  visitors"
                </p>
              </div>
            </div>
            <div className="flex-container">
              <div className="member-text-container">
                <p>We love working THIS WAY and our clients do too</p>
              </div>
              <div className="image six" />
            </div>
            <div className="flex-container">
              <div className="image seven" />
              <div className="member-text-container">
                <h3>Miriyam</h3>
                <p>
                  "I love the agility and cozy environment that the officeThis
                  Way provides, meaning I can enjoy my coffee on the sofa while
                  working and feeling productive"
                </p>
              </div>
            </div>
            <div className="flex-container">
              <div className="member-text-container">
                <p>
                  This Way Coffee experience is 2nd to none with the 1st ever in
                  BG digital coffee machine and our freshly brewed This Way
                  Coffee alongside the beautiful energizing green wall
                </p>
              </div>
              <div className="image eight" />
            </div>
            <div className="flex-container">
              <div className="image nine" />
              <div className="member-text-container">
                <h3>Dani</h3>
                <p>
                  "I love the inspiring artwork in This Way’s our superhero
                  meeting rooms, Wonder Woman is perfect for in person, or
                  online meetings and presentations"
                </p>
              </div>
            </div>
          </>
        ) : (
          <div className="flex-container">
            <div className="image-wrapper">
              <div className="team-image one" />
              <div className="text-wrapper">
                <p id="bold">Ella</p>
                <p id="quote">
                  "I love the large, openable windows and natural light in the
                  office"
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <div className="team-image two" />
              <div className="text-wrapper">
                <p id="quote">
                  THIS WAY Hospitality and creating the best experience for our
                  clients is our priority
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <div className="team-image three" />
              <div className="text-wrapper">
                <p id="bold">Bori</p>
                <p id="quote">
                  "The library walls are is by far my favorite feature - a
                  unique piece of art. ,I find it very refreshing and inspiring,
                  especially our our “take pick a book & , leave drop a book
                  initiative” concept and the amazing books we get to share and
                  read."
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <div className="team-image four" />
              <div className="text-wrapper">
                <p id="quote">
                  Teamwork, Networking and Collaboration are essential - at THIS
                  WAY we Collaborate THIS WAY and you can too
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <div className="team-image five" />
              <div className="text-wrapper">
                <p id="bold">Giles</p>
                <p id="quote">
                  "The stylish design features, such as this Eames Lounge Chair,
                  are my favorite parts about the office, they add a lot of
                  character as well as comfort to our valuable clients and
                  visitors"
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <div className="team-image six" />
              <div className="text-wrapper">
                <p id="quote">
                  We love working THIS WAY and our clients do too
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <div className="team-image seven" />
              <div className="text-wrapper">
                <p id="bold">Miriyam</p>
                <p id="quote">
                  "I love the agility and cozy environment that the officeThis
                  Way provides, meaning I can enjoy my coffee on the sofa while
                  working and feeling productive"
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <div className="team-image eight" />
              <div className="text-wrapper">
                <p id="quote">
                  This Way Coffee experience is 2nd to none with the 1st ever in
                  BG digital coffee machine and our freshly brewed This Way
                  Coffee alongside the beautiful energizing green wall
                </p>
              </div>
            </div>
            <div className="image-wrapper">
              <div className="team-image nine" />
              <div className="text-wrapper">
                <p id="bold">Dani</p>
                <p id="quote">
                  "I love the inspiring artwork in This Way’s our superhero
                  meeting rooms, Wonder Woman is perfect for in person, or
                  online meetings and presentations"
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default MeetingsComponentWhite;
