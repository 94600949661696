import React from "react";
import { LocationsSection, ContactForm } from "../../components";
import "./styles.scss";

function Locations() {
  return (
    <div className="locations-screen-container">
      <LocationsSection notHome />
      <ContactForm />
    </div>
  );
}

export default Locations;
