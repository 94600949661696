import React from "react";
import { connect } from "react-redux";
import {
  ViewMoreComponent,
  BlackGridComponent,
  WhiteGridComponent,
  TitleComponent,
} from "../../components";
import { translate } from "../../translations/localization";
import { history } from "../../config/stores";
import "./styles.scss";

function GridHomeComponent({ language }) {
  return (
    <div className="grid-home-container">
      <div className="left-container">
        <div className="text-container">
          <TitleComponent title1="WHY" title2="THIS WAY" />
          <h3 style={(language === "bg" && { fontFamily: "bold" }) || {}}>
            {translate("homeSubText4")}
          </h3>
          <p style={(language === "bg" && { fontFamily: "thin" }) || {}}>
            {translate("homeText5")}
          </p>
          <ViewMoreComponent
            textBold={translate("buttonText1")}
            textLight={translate("buttonText2")}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/WhyThisWay`);
            }}
          />
        </div>
      </div>
      <div className="right-container">
        <WhiteGridComponent
          text={`“Sterling Serviced Office Group 
                launches Coworking brand”`}
          img="/icons/all-work-logo.svg"
          onClick={() => {
            window.open(
              "https://allwork.space/2020/02/sterling-serviced-office-group-launches-coworking-brand/"
            );
          }}
        />
        <BlackGridComponent
          mainText={`“Best event space I’ve ever been 
            to, cozy and modern interior, 
            wonderful & professional team…”`}
          subText="-Boriana Jeleva"
          img="/logos/httpool-logo-white.svg"
        />
        <WhiteGridComponent
          text={`“This Way: Finding Quality”`}
          img="/icons/vagabond.svg"
          onClick={() => {
            window.open("https://vagabond.bg/way-finding-quality-2616");
          }}
        />
        <BlackGridComponent
          mainText={`“This Way is an amazing and 
            special place with very good 
            customer service....” `}
          img="/icons/RM-logo.svg"
        />
        <WhiteGridComponent
          text={`“This Way Coworking Offices - 
            Sofia”`}
          img="/icons/office-snapshots-logo.svg"
          onClick={() => {
            window.open(
              "https://officesnapshots.com/2020/06/08/this-way-coworking-offices-sofia/"
            );
          }}
        />
        <BlackGridComponent
          mainText={`“Every single detail here vibrates 
            with the purpose to boost your 
            creativity, and invites you to 
            deliver nothing more than your 
            highest performance…”
            `}
          img="/icons/fidweb-logo.svg"
        />
        <WhiteGridComponent
          text={`“This Way - Coworking Hub” `}
          img="/icons/archello.svg"
          onClick={() => {
            window.open("https://archello.com/project/this-way-coworking-hub");
          }}
        />
        <BlackGridComponent
          mainText={`“Our team members enjoy 
            working in This Way's beautiful 
            office space. We're grateful for 
            their top notch service which 
            keeps everyone efficient and 
            productive." 
            `}
          subText="-Diana Malatsilajeva"
          img="/icons/truevo-logo-white.svg"
        />
        <WhiteGridComponent
          title="BIGSEE Interior 
            Design Award 
            2020 Winner"
          img="/icons/big.svg"
          onClick={() => {
            window.open("https://bigsee.eu/this-way-coworking-hub-sofia/");
          }}
          larger
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.selectedLanguage,
});

export default connect(mapStateToProps, null)(GridHomeComponent);
