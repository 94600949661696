import React, { useState } from "react";
import { ViewMoreComponent, TitleComponent } from "../../components";
import { connect } from "react-redux";
import { setStory } from "../../actions";
import { history } from "../../config/stores";
import { translate } from "../../translations/localization";
import firstStoryImage from "../../assets/storyImage/story-1.jpg";
import "./styles.scss";

const totalStories = [
  {
    title: "Green Wall – Not Just a Decoration!",
    text: `When I first stepped into THIS WAY for my job interview, I was very impressed by the design, the fit out of the space, the colors that flow and complement each other but the most impressive thing wasespecially the live Green Wall next to reception. 
    Now I work next to this beautiful wall every day, full of different plants, some of which are blooming, others cascading over the next ones. but what I couldn’t didn’t realize, till until I started writing this article, is the immense amount of care that they provide to all of us every day.`,
    image: firstStoryImage,
    textArray: [
      `When I first stepped into THIS WAY for my job interview, I was very
      impressed by the design, the fit out of the space, the colors that
      flow and complement each other but the most impressive thing
      especially the live Green Wall next to reception.`,
      `Now I work next to this beautiful wall every day, full of different plants, some of which are blooming, others cascading over the next ones. I didn’t realize, until I started writing this article, the immense amount of care that they provide to all of us every day. It is a gentle, almost invisible, care which need to be brought under the bright lights. The name is Biophilic design and no, I’m not going to bore you to death with terms, facts or statistics, that’s not the point. The simple meaning is that, as a human, every one of us is carrying an attraction to nature in all of its forms and expressions.`,
      `When we want to go on holiday, no matter short or long, we think about activities like laying under the sun on a nice beach, hiking in mountains, walking around national parks and searching for the greatest views to admire and stamp into our minds forever. All of this activities and places are connected with nature. We are drawn towards nature for a rest because waking up with ocean view or being surrounded by lush forests and plants is so relaxing and therapeutic for our mental health.`,
      `Nowadays, we are so busy with work, home tasks, worries about almost everything, that we just walk around the cities, overstressed and mentally exhausted. Here comes the mission of Biophilic design which can be easily implemented inside and out. That’s why the Green Wall in THIS WAY is not just for decoration, it serves an important purpose. It cleans the air and helps to reduce stress and anxiety levels, creates a balance between our work life and mental health. There are many studies that prove the benefits of live plants in the workplace. They increase creativity, help with focus, lower stress and anxiety, and also provide a great conversation starter.`,
      `So, I encourage everyone to implement few breaks for a cup of coffee and while drinking ittime to admire just observe the Green Wall and reenergize yourself for the rest of the day. Take thatImplement that moment of calm and reflection into your daily routine  small care daily, because mental health is important, and but the most important is that you will feel amazing with just that small change.`,
      `That’s why the Green Wall in THIS WAY is not only just an interior peace piece of interior decoration, but ait is vital part of the atmosphere and the wellbeing of all of us.`,
      `Reenergize THIS WAY!`,
    ],
    author: "By Daniela",
  },
  {
    title: `THIS WAY Shared Library Concept – “ Drop a Book & Pick a Book”`,
    text: `The idea behind the two symmetrical library walls at THIS WAY was inspired by our love for 
    books, being closer to nature, the sustainability effort and desire to preserve the old and new 
    through time. The shape and design of the library was custom made, from natural oak wood 
    and the geometrical shapes symbolize that nature and geometry are interconnected.
    “All of nature evolves out of simple geometric patterns incorporated within the molecular 
    “seed” structure.`,
    image: "/storyImages/story-2.jpg",
    textArray: [
      `“It is the harmony of the diverse parts, their symmetry, their
      happy balance; in a word it is all that introduces order, all
      that gives unity, that permits us to see clearly and to
      comprehend at once both the ensemble and the details.” – Henry
      Poincare`,
      `The idea behind the two symmetrical library walls at THIS WAY was inspired by our love for 
      books, being closer to nature, the sustainability effort and desire to preserve the old and new 
      through time. The shape and design of the library was custom made, from natural oak wood 
      and the geometrical shapes symbolize that nature and geometry are interconnected.`,
      `“All of nature evolves out of simple geometric patterns incorporated within the molecular 
      “seed” structure. Each of these basic patterns contains information that enables animals, 
      plants, minerals (and humans) to develop into complex and beautiful forms, each with an 
      intrinsic awareness of its location in space and time. Being part of nature, we have a 
      relationship with it at the cellular level which is experienced vibrationally, and which is nurturing. 
      When these seed patterns are incorporated into our architecture, a vibrational exchange takes 
      place between the building and its occupants in a way that is similar to the connection we have 
      with nature, and which leads to a sense of well being.`,
      `Buildings can have a profound influence on our health and our psychic and spiritual state of 
      being, Harmony and balance, light and color, relationship to landscape, ecological sympathy, 
      energy efficiency and geometric form are contributing elements of shelter which aspire to be 
      nurturing rather than draining. We resonate at both cellular and consciousness levels with our 
      environment. By creating an environment around us that is supportive to both our inner and 
      our outer senses, we can enhance rather than alienate our human links with nature. 
      Architecture, when employed as a means of embodying principles of universal harmony can 
      sustain us rather than drain us, so that our homes become our havens, and our work places 
      support our creativity." - Source: Archinomy`,
      `“A room without books, is like a body without a soul” - CICERO`,
      `Why Shared Library THIS WAY Concept: Drop a Book & Pick a Book? To encourage 
      ourselves and people to continue reading and enjoying the magic of books, while trying to be 
      more environmentally friendly. We live in an e-world filled with technology and e-books are part 
      of this – but culture and its traditions can never be completely replaced and some book readers 
      have their unique preferences.`,
      `Some facts: Producing one book consumes two kilowatt hours of fossil fuels and 
      approximately 7.5 kilograms of carbon dioxide, for a total of 100 times fewer greenhouse gases 
      than those caused by the production of one e-reader.`,
      `Books Vs. E-Books: Any other environmentally friendly alternatives to consider? “We are so often surrounded by an endless supply of printed books, magazines, and 
      newspapers that it is easy to become desensitized to or forget about their negative impact on 
      our environment. However, in 2008 alone, the publishing industry was responsible for the 
      harvest of nearly 125 million trees. However, the average carbon footprint associated with the 
      production of a single paper book is only about 7.5kg. There is the fact that producing a single 
      e-reader requires the extraction of nearly 33lb of minerals and uses about 79 gallons of water. 
      It should come as no surprise that traditional, printed books do not require even close to the 
      same amount of resources, and being aware of these simple facts makes it crystal clear that 
      e-readers are not a perfect solution. However, depending on individual reading habits, 
      they can serve as a way to avoid excess CO2 emissions, and it is for these reasons that this 
      issue of the utmost importance.`,
      `It should be also noted this debate is not restricted to only these two options. Though it has 
      been framed from this perspective, there are a few other paths to mention. Firstly, the value of 
      libraries should not be underestimated. They act as yet another method of reducing 
      environmental impact by sharing one collection with many people. As was mentioned, printing 
      a single book has a carbon footprint of about 7.5kg CO2. This seemingly small number can 
      become quite significant when it is multiplied by a large number of consumers that each want 
      their own copy of the book, but in the case of a library, it can remain small and relatively 
      inconsequential; fewer books can serve a larger number of people. This is a fairly 
      environmentally responsible method that should be mentioned more frequently in the books 
      vs e-books debate. “ - Source: The Eco Guide`,
      `What books can you drop / pick: Any type – autobiography, fiction, business, finance, 
      motivational, history, art, cooking, sustainability, gardening, etc. Any author, any language, any 
      period. New or Old! How it works: When you pick a book pass by reception so they can register 
      pick up and return date. Enjoy reading!`,
      `“Reading gives us a place to go when we have to stay where we are” – Mason Cooley`,
      `If you are a writer, publisher or have a list of books you strongly recommend, send us a 
      message or visit us, we would love to hear from you!`,
      `Let's Read THIS WAY`,
    ],
    author: "By Borislava",
  },
  {
    title: `Sofia: The Underground Innovation Hub of Europe`,
    text: `Bulgaria, a small, unassuming country nestled on the south east corner of Europe, is actually an unlikely incubator for some of Europe’s newest and brightest businesses. This year, Sofia was included in fDi Magazine and TNW’s Technology Cities of the Future report, citing Sofia as the 7th most cost-effective city for business in tech. Eastern Europe’s developing technology and innovation ecosystems are not a new phenomenon, but what makes Sofia, Bulgaria’s capital, so special?`,
    image: "/storyImages/story-3.jpg",
    textArray: [
      `Bulgaria, a small, unassuming country nestled on the south
      east corner of Europe, is actually an unlikely incubator for
      some of Europe’s newest and brightest businesses. This year,
      Sofia was included in fDi Magazine and TNW’s Technology Cities
      of the Future report, citing Sofia as the 7th most
      cost-effective city for business in tech. Eastern Europe’s
      developing technology and innovation ecosystems are not a new
      phenomenon, but what makes Sofia, Bulgaria’s capital, so
      special?`,
      `As the oldest country in Europe, founded in 681, innovation is rooted in Bulgarian history, having invented the Cyrillic alphabet by Saints Cyril and Methodius which is now the basis of languages across Eastern Orthodox Slavic countries. Other key innovators of Bulgaria include Stamen Grigorov, a Bulgarian scientist that discovered the bacteria that turns milk into yogurt and John Atanasoff, who invented the first electronic digital computer in the 1930s.`,
      `Bulgaria’s location plays a crucial role in its growing popularity for IT companies. Sofia is in a ‘goldilocks zone’, still in the European Union but outside of the competitive circle of central and Western European countries, making it a close outsourcing hub for businesses across the continent. Many multinationals have chosen Sofia as their base as a result, such as German software company SuSE and instant messaging software company Viber.  On the other hand, it isn’t just established businesses that are benefiting from Sofia’s unique characteristics. Sofia was ranked by Forbes as one of the top ten best countries in the world to start a business, listing reasons such as a very low-income tax rate (10%), one of the fastest internet speeds in the world, and also access to EU investment funds.`,
      `Additionally, Bulgaria is home to a highly skilled workforce. In high-schools across the country, there is a focus on IT and mathematics, as well as a solid second language education. This combination creates individuals who are perfect for the international nature of the IT sector. For this reason, especially in the tech industry, labour relies very little on migration. Only 6% of the workforce across Eastern Europe is composed of immigrants, whereas in the UK, for example, 44% of the private tech start-up industry are migrants.`,
      `The booming tech industry has done fantastic things for Bulgaria, not only has the country seen steady economic growth, but also has been hugely beneficial in attracting innovation and talent. Interestingly, although fDi’s report states that most countries rely on government-led initiatives to help create these ‘tech cities’, Bulgaria’s success comes from mostly a private-sector led effort through investment funds such as Eleven, Startup Accelerator, Neveq and LAUNCHub.`,
      `Additionally, infrastructure to support innovation has emerged to support these businesses, such as Sterling Office Group, which was the first serviced office operator in Bulgaria in 2002. As the working tendencies have evolved over the last 20 years, a demand for flexible workspace prompted the creation of This Way Coworking Hub, Sterling’s sister company. Coworking and flexible workspaces provide a wide range of ways that companies can meet, work and collaborate.`,
      `As a result, Sofia is the perfect place for not only incubating start-ups, but also for outsourcing for established businesses. The lifestyle is easy, everything is in very close proximity, and the culture is laidback and friendly. Not to mention, Sofia is located on the foothills of a the beautiful Vitosha mountain, and Bulgaria is home to some of the most picturesque and untouched nature.`,
      `Could Sofia be your next destination for business? Contact us, at This Way, to use our coworking and private office space as your base during your exploration of Bulgaria’s innovation hub.`,
    ],
    author: "By Ella",
  },
  {
    title: `Wellness in the Workspace`,
    text: `After observing the evolution of working practices towards flexible workspaces and coworking, it became is becoming increasingly apparent, while creating the Sterling Serviced Office Group and THIS WAY Coworking, that a workplace is far more than an office with desk and a chair. Along with a movement away from a highly corporate work environment, people are starting to demand far more things when it comes to the space around them. For example, lighting company Ketra surveyed their employees on what they considered the most important features to include when designing their new office.`,
    image: "/storyImages/story-4.jpg",
    textArray: [
      `After observing the evolution of working practices towards flexible workspaces and coworking, it became is becoming increasingly apparent, while creating the Sterling Serviced Office Group and THIS WAY Coworking, that a workplace is far more than an office with desk and a chair. Along with a movement away from a highly corporate work environment, people are starting to demand far more things when it comes to the space around them. For example, lighting company Ketra surveyed their employees on what they considered the most important features to include when designing their new office. Results showed that employees valued natural light, different spaces for taking phone calls, biophilia in the workplace and design features that align with an active lifestyle.`,
      `Forward-thinking companies are pushing for an environment that encourages productivity, collaboration and a healthy work-life balance for their employees. Wellness in the workspace encompasses both mental and physical health and can not only benefit your employees but the success of your business. `,
      `Physical Health
      Wellness in its most fundamental form refers to physical health, which can make an obvious impact on an individual’s productivity and happiness. Some of the most basic elements of a healthy environment include fresh air, frequently cleaned facilities, access to healthy foods, and the encouragement to be physically active.  People spend most of their weekdays in their offices, so being in a physically healthy work environment is extremely important for their wellbeing. A 2019 study published by BMC Public Health showed that it is possible for workplace interventions that encourage healthy lifestyle to positively influence work-related outcomes like productivity and concentration. Encouraging workplace nutrition and physical activity can have a real impact on employee work performance as well as health.`,
      `AtFor example, at This Way coworking, we offer weekly fruit days to our clients which is a fantastic way to encourage healthy eating and we provide the option to have a multisport gym and sports card. Additionally, we ensure the cleanliness of our facilities as well as well-ventilated spaces with openable windows. Our open, reconfigurable space has been used for yoga classes, meditation classes, and seminars to discuss varied topics on wellness.`,
      `Comfort 
      Ensuring that your employees have a comfortable work environment should be a top priority for your business and is fundamental to the health and productivity of your employees. This can be done through providing your employees with ergonomic chairs for your offices and choosing coworking spaces that provide comfortable seating. Additionally, especially in flexible workspaces, employees can greatly benefit from being provided the option of spaces to sit and work in. Some employees may prefer standard desk chairs only, where others might require changing seating positions throughout the day from sofas to barstools. The Ketra employee survey showed that many people want a variation in seating, such as sit/stand desks which align with their active lifestyle.`,
      `At This Way, we provide a range of seating options for our clients, such as regular desk chairs, private padded pods, sofas and bar stools. We believe that flexible spaces give people the freedom to choose how they work best, to ensure productivity and happiness.`,
      `Mental Health
      Mental health is one of the most important, but also the most overlooked, element of wellness that should be emphasized in the workspace. In 2016, nearly 1 in 5 Americans reported mental health issues. This staggering figure that shows that although it isn’t often discussed, mental health issues can be detrimental to the productivity, community and physical capability of many employees. Additionally, A happy work environment can attract, and retain, talent for your business. Elements of mental comfort should be provided to your employees, such as options for socialization and teamwork, as well as private spaces. Additionally, the presence of visually appealing features and a well-lit workspace is vital for the mental health of employees. Biophilic design elements are especially effective at helping people feel more connected to nature and thus more productive, creative and present.`,
      `At This Way, elements such as the green wall, and our art murals across the office bring in these elements of biophilic design and artwork to help inspire and stimulate our clients to provide a positive work environment. Additionally, our workspace has many different areas for the varied needs of our clients. Our café area and communal workspace provide a community atmosphere, whereas our phone booths, meeting pods and private offices can fulfil the need for privacy.`,
      `The sense of community is strong at Tthis Wway, as an office space for likeminded professionals, we find that even our clients with private offices love to come into our communal spaces to have a coffee and meet each other. Additionally, we provide online platforms, such as our app and Facebook group, to keep our community up to date with events happening in the office, as well as provide a platform for discussion, collaboration and networking. `,
      `Beneficial to your Business
      By ensuring wellness in your workspace, your business with greatly benefit. Showing that you care about your employees can help you attract and maintain talent. More than ever, people are understanding the importance of wellness, and seek out a positive environment to work in. This Way, we understand that all of these parts of wellness are extremely important to the productivity and physical and mental happiness of our clients. This is why these elements have been incorporated into our coworking space, and why we continue to encourage physical and mental health and ensure comfort and community to our clients.`,
    ],
    author: "By Ella",
  },
  {
    title: `Words from Our Founder: Giles Blanchard`,
    text: `Tell us more about the idea behind THIS WAY Coworking – how did you decide to dedicate yourself to this industry, describe your coworking space and what sets you apart from the rest of the coworking spaces?  
    When I first arrived in Sofia from Hong Kong in 2003, I discovered, during my search for office space, that there were no serviced offices. I realised that there was a need in the market and opened the first serviced office in Bulgaria in 2004.`,
    image: `/storyImages/story-5.jpg`,
    textArray: [
      `Tell us more about the idea behind THIS WAY Coworking – how did you decide to dedicate yourself to this industry, describe your coworking space and what sets you apart from the rest of the coworking spaces?`,
      `When I first arrived in Sofia from Hong Kong in 2003, I discovered, during my search for office space, that there were no serviced offices. I realised that there was a need in the market and opened the first serviced office in Bulgaria in 2004. Since then, the Sterling Office group has opened a second location in Sofia, and also a branch in Istanbul. After 17 years of experience in the serviced office industry, I observed the evolution of working practices towards flexible working, shared facilities and collaboration. This Way was created as a sister brand to help businesses transform the way they work, focused around a large, collaborative, multifunctional space, with a café area as the focal point for meeting and community. A comment I often get from visitors is how incredibly large the communal space really is, which is very unique to Sofia. On the other hand, we also offer top quality private office space, with 250 work-stations across 33 offices. We have four fully equipped, superhero themed meeting rooms, as well as additional on-demand meeting spaces, pods and phone booths. As a result, we have a diverse group of clients, from multinationals to entrepreneurs. Our design, which focuses on natural materials, exposed concrete and beautiful lighting features, has drawn a lot of attention. We’ve hosted many types of events, from tv commercials, to workshops, seminars and parties. I think what sets us apart the most from the rest of the coworking spaces in Sofia is our experience working with some of the biggest companies in the world. We provide that level of service, quality and professionalism to a more relaxed coworking environment, while maintaining security and the best prop tech on the market.`,
      `You share that you focus on synergy between the culture, art, nature and the latest technology and overlap these elements in THIS WAY Coworking. Why is this so valuable for you and is it appreciated by the Bulgarian client?`,
      `All of these elements are integral to providing our clients with the most pleasant and productive space to work in. The way individuals view their workspace is very different now, and an office isn’t just a desk and chair anymore, but an environment that you spend most of your waking time in. Additionally, the movement away from using desktop PCs, towards laptops, has meant that we can elevate the work experience of our clients by providing a range of areas, from sofas, to bar seating to private pods, to suit their needs. By including a lush green wall in the space and art murals, we’re creating an inviting space that you want to spend time in, all our clients appreciate this outlook on work.`,
      `To what extent are the Bulgarian clients accepting in principle of the more open type of space in coworking – with open spaces, community areas, even pet friendly -  – what type of people and companies are your clients?`,
      `Bulgarian companies aren’t just accepting this new way of working but demanding it. Many forward-thinking companies are coming to This Way in search of community areas, flexible work practices and the service we provide. We notice that even our clients that have private offices often choose to sit in the communal area to work on the sofa or take a zoom call with their coffee. Due to our range of choices, with private office space as well as coworking available, we have a wide variety of clients, from freelance photographers to management consultants, pharmaceutical companies to designers. We’re able to cater to the needs of companies with varied work practices.`,
      `What are the benefits and advantages for the clients who us your coworking space?  For what type of clients is it perfect?`,
      `We can fit the needs of almost any kind of client, however big or small. We have private office space, coworking flex desk, and team desk packages, as well as hybrid packages tailored for a company’s specific needs, with a combination of private office space as well as coworking packages. There are many benefits to using This Way, both for a business, as well as their employees. Our all-inclusive pricing means we have one contract, one invoice and one point of contact with zero capital expenditure. Our flexible terms mean that a business can choose to rent an office for the period of time that suits them, instead of having to commit to 12- or 24-month leases like in traditional office spaces. Additionally, we provide a choice of private and communal spaces that are managed by our fantastic team. Employees benefit by working in a space with ergonomic furniture, high ceilings, openable windows and beautiful soft furnishings. We enhance our clients day-to-day experience with our interesting design elements, curated artwork, our shared-library concept and biophilic areas. We provide an opportunity for people to get together, share ideas and experiences.`,
      `Do you find there are disadvantages of the coworking model in relation to the traditional “standard” type office?`,
      `We find that as long as we provide areas in our space where people can get privacy, there are no real downsides to coworking. As a solution, we have many private areas for all our clients to use, such as meeting pods, and phone booths, perfect for informal meetings and private conversations, as well as fully fitted meeting rooms for presentations and decision-making. Generally, our clients are choosing to move away from wanting a traditional office structure, and are embracing the benefits of coworking.`,
      `What are the challenges you face in the last months of crisis, how did you adapt your work and services?`,
      `Of course, it hasn’t been easy, as people have been told to work from home instead of coming to the office. On the other hand, businesses are realizing that home office is not a solution for many employees, as they don’t have a space suitable at home for productive work. We continue to stay open by prioritising safety. Our strict procedures include rigorous and regular cleaning and  disinfection of all areas of the spaces, a reconfiguration of the coworking space, event area and meeting rooms to allow for physical distancing and touchless access to the space and a convenient app for information, communication and meeting room bookings. We have also found opportunity in this pandemic. I believe that the natural move towards flexible work has been accelerated by the pandemic by at least 5 years. Many polls are showing that companies are going to begin using hybrid-work models, a combination of office-space, coworking, and remote working, moving forward from the pandemic. I think it will be fascinating to observe how the way people work continues to change in a post-pandemic world.`,
      `Did your future plans change and what do you plan now for the development of your business?`,
      `Our future plans haven’t changed, if anything, I’ve received a lot of interest from companies wanting to incorporate coworking spaces into their offices. We’re planning for future expansion, hopefully in the new year, and the future is bright.`,
    ],
    author: "By Giles Blanchard",
  },
];

function StoriesHomeComponent({ setStory, language }) {
  const [storyIndex, setStoryIndex] = useState(0);
  return (
    <div
      className="stories-home-container"
      style={{ backgroundImage: `url(${totalStories[storyIndex].image})` }}
    >
      {window.innerWidth < 900 && (
        <div className="mobile-buttons-container">
          <div
            className="left-button"
            onClick={() => {
              if (storyIndex > 0) {
                setStoryIndex((prev) => prev - 1);
              }
            }}
          />
          <div
            className="right-button"
            onClick={() => {
              if (storyIndex < totalStories.length - 1) {
                setStoryIndex((prev) => prev + 1);
              }
            }}
          />
        </div>
      )}
      <div className="white-overlay">
        <div className="text-container">
          <TitleComponent title1={translate("homeTitle")} title2="THIS WAY" />
          <h3>{totalStories[storyIndex].title}</h3>
          <p>{totalStories[storyIndex].text}</p>
          <ViewMoreComponent
            textBold={translate("buttonText7-1")}
            textLight={translate("buttonText1")}
            right
            noFlex
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              setStory({
                title: totalStories[storyIndex].title,
                text: totalStories[storyIndex].textArray,
                image: totalStories[storyIndex].image,
              });
              history.push(`/${language}/SingleStory`);
            }}
          />
          <ViewMoreComponent
            textBold={translate("buttonText7-2")}
            textLight={translate("buttonText7")}
            right
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
              history.push(`/${language}/Stories`);
            }}
          />
        </div>
        <div className="buttons-container">
          <div
            className="left-button"
            onClick={() => {
              if (storyIndex > 0) {
                setStoryIndex((prev) => --prev);
              }
            }}
          />
          <div
            className="right-button"
            onClick={() => {
              if (storyIndex < totalStories.length - 1) {
                setStoryIndex((prev) => ++prev);
              }
            }}
          />
        </div>
      </div>
      {window.innerWidth < 900 ? (
        <div
          className="stories-image"
          style={{ backgroundImage: `url(${totalStories[storyIndex].image})` }}
        />
      ) : null}
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  setStory: (payload) => dispatch(setStory(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StoriesHomeComponent);
