import React from "react";
import { TitleComponent, ViewMoreComponent } from "../../components";
import { history } from "../../config/stores";
import { connect } from "react-redux";
import { setStory } from "../../actions";
import { translate } from "../../translations/localization";
import firstStoryImage from "../../assets/storyImage/story-1.jpg";
import "./styles.scss";

function MainStoryComponent({ language, setStory }) {
  return (
    <div className="main-story-container">
      <div className="left-container">
        <div className="text-container">
          <TitleComponent title1="STORY" title2="IN FOCUS" />
          <h3>Green Wall – Not Just a Decoration!</h3>
          <p>
            When I first stepped into THIS WAY for my job interview, I was very
            impressed by the design, the fit out of the space, the colors that
            flow and complement each other but the most impressive thing
            wasespecially the live Green Wall next to reception. Now I work next
            to this beautiful wall every day, full of different plants, some of
            which are blooming, others cascading over the next ones. but what I
            couldn’t didn’t realize, till until I started writing this article,
            is the immense amount of care that they provide to all of us every
            day.
          </p>
          <p id="italic">By Daniela</p>
          <ViewMoreComponent
            right
            textBold={translate("readMore1")}
            textLight={translate("readMore2")}
            onClick={() => {
              setStory({
                title: "Green Wall – Not Just a Decoration!",
                text: [
                  `When I first stepped into THIS WAY for my job interview, I was very impressed by the design, the fit out of the space, the colors that flow and complement each other but especially the live Green Wall next to reception.`,
                  `Now I work next to this beautiful wall every day, full of different plants, some of which are blooming, others cascading over the next ones. but what I couldn’t didn’t realize, till until I started writing this article, is the immense amount of care that they provide to all of us every day. It is a gentle, almost invisible, care which need to be brought under the bright lights. The name is Biophilic design and no, I’m not going to bore you to death with terms, facts or statistics, that’s not the point. The simple Simply the meaning is that, as a human, every one of us is carrying an attraction to nature in all of its forms and expressions. Let’s think about that  Wwhen we want to go on holiday, no matter short or long, we think about activities like laying under the sun on a nice beach, hiking in mountains, walking around national parks and searching for the greatest views to admire and stamp into our minds forever. All of this activities and places are connected with nature. We are drawn towards go for a rest in nature for a rest because waking up with ocean view or being surrounded by lush forests and plants is so relaxing and therapeutic for our mental health.`,
                  `Nowadays, we are so busy with work, home tasks, worries about almost everything, that we just walk around the cities, overstressed and mentally exhausted. Here comes the mission of Biophilic design which can be easily implemented inside and out. That’s why the Green Wall in THIS WAY is not just for decoration, it serves an important purpose. It cleans the air and helps to reduce ing the stress and anxiety levels, creates a balance between our work life and mental health. There are many studies that prove the benefits of live plants in the workplace. They increase creativity, help with focus, lower stress and anxiety which are common problems for most people, and also provide a great also help with starting a conversation and improves the networking skillsstarter.`,
                  `So, I encourage everyone to implement few breaks for a cup of coffee and while drinking ittime to admire just observe the Green Wall and reenergize yourself for the rest of the day. Take thatImplement that moment of calm and reflection into your daily routine  small care daily, because mental health is important, and but the most important is that you will feel amazing with just that small change.`,
                  `That’s why the Green Wall in THIS WAY is not only just an interior peace piece of interior decoration, but ait is vital part of the atmosphere and the wellbeing of all of us.`,
                  `Reenergize THIS WAY!`,
                ],
                image: firstStoryImage,
                author: "By Daniela",
              });
              history.push(`/${language}/SingleStory`);
            }}
          />
        </div>
      </div>
      <div
        className="right-container"
        style={{ backgroundImage: `url(${firstStoryImage})` }}
      ></div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  language: state.language.selectedLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  setStory: (payload) => dispatch(setStory(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainStoryComponent);
