import React, { useEffect } from "react";
import "./styles.scss";

function TitleComponent({
  title1,
  title2,
  trigger,
  animation,
  id,
  noMargin,
  bulgarianSpecial,
}) {
  useEffect(() => {
    if (trigger && animation) {
      setTimeout(() => {
        let title1 = document.getElementsByClassName(`title-one ${id}`);
        if (title1.length) {
          title1[0].className = "title-one triggered";
        }
      }, 2000);
      setTimeout(() => {
        let title2 = document.getElementsByClassName(`title-two ${id}`);
        if (title2.length) {
          title2[0].className = "title-two triggered";
        }
      }, 3000);
    }
  }, [trigger, animation, id]);
  const chosenLanguage = localStorage.getItem("code");

  return (
    <div className="title-container" style={noMargin && { marginBottom: 0 }}>
      <h1
        className={`title-one ${id} ${bulgarianSpecial && "smaller"} `}
        id="bold"
        style={chosenLanguage === "bg" ? { fontFamily: "bold" } : {}}
      >
        {title1}
      </h1>
      <h1
        className={`title-two ${id} ${bulgarianSpecial && "smaller"} `}
        id="light"
        style={chosenLanguage === "bg" ? { fontFamily: "light" } : {}}
      >
        {title2}
      </h1>
    </div>
  );
}

export default TitleComponent;
